<template>
  <b-toast
    :visible="isVisible"
    :no-auto-hide="true"
    :no-close-button="true"
    toaster="b-toaster-bottom-left"
    :body-class="{'bg-production': isProductionEnv, 'bg-development': !isProductionEnv}"
  >
    {{ environmentName }}.
  </b-toast>
</template>
  
<script>
const { VUE_APP_ENV } = process.env;
import {
  BToast
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import { Environments } from '@models';

export default {
  name: 'AomEnvironment',
  components: {
    BToast
  },
  computed: {
    ...mapGetters('profile', ['isLoggedIn', 'loggedUser']),
    isAomEmployee() {
      const matchingString = '@artofmentoring.net';
      return this.loggedUser?.email.toLowerCase().includes(matchingString.toLowerCase());
    },
    environmentName() {
      return VUE_APP_ENV;
    },
    isProductionEnv() {
      return this.environmentName.toLocaleLowerCase() === Environments.PRODUCTION;
    },
    isPrintPage() {
      return this.$route.meta.printPage;
    },
    isVisible () {
      return (this.isProductionEnv && this.isAomEmployee) || !this.isProductionEnv && !this.isPrintPage;
    },
  },
};
</script>
  
<style lang="scss" scoped>
::v-deep.b-toast .toast {
    color: white;
    text-transform: capitalize;
    width: 250px;
    .bg-production {
        background-color: rgb(234, 84, 85);
        border-radius: 5px;
    }
    .bg-development {
        background-color: var(--teal);
        border-radius: 5px;
    }
}
</style>
  
