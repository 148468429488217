import client from './http';

class MatchPreferenceService {

  constructor(axios) {
    this.axios = axios;
  }

  getUserMatchPreferences(programId, userId) {
    return this.axios.get(`/programs/${programId}/user/${userId}/match-preferences`);
  }
  postSaveMatchPreferences(programId, data) {
    return this.axios.post(`/programs/${programId}/match-preferences`, data);
  }
}

const matchPreferenceService = new MatchPreferenceService(client);
export default matchPreferenceService;
