import client from "./http";

class ChampionsService {
  constructor(axios) {
    this.axios = axios;
  }

  getChampionsByProgram(programId, search) {
    return this.axios.get(`/programs/${programId}/users/champions`, {params: {search}});
  }

  getChampionsAndPAByProgram(programId, search) {
    return this.axios.get(`/programs/${programId}/users/champions-and-pa`, {params: {search}});
  }

  addChampionsToProgram(programId, data) {
    return this.axios.post(`/programs/${programId}/users/champions`, data);
  }

  update(userId, data) {
    return this.axios.patch(`/users/${userId}`, data);
  }
  updateChampion(clientId, userId, data) {
    return this.axios.patch(`clients/${clientId}/users/${userId}`, data);
  }

  getChampionsByClient(clientId, search) {
    return this.axios.get(`/clients/${clientId}/users/champions`, {params: {search}});
  }

  delete(userId) {
    return this.axios.delete(`/users/${userId}`);
  }

  createChampionForClient(clientId, data) {
    return this.axios.post(`/clients/${clientId}/users/champions`, data);
  }

  addProgramAdminToProgram(programId, data) {
    return this.axios.post(`/programs/${programId}/users/program-admins`, data);
  }

  getParticipantsByClient(clientId) {
    return this.axios.get(`/clients/${clientId}/users`);
  }
  deleteUserClientChampionRoles(clientId, userId ) {
    return this.axios.delete(`/clients/${clientId}/users/${userId}/champion`);
  }
  deleteUserProgramChampionRole(programId, userId) {
    return this.axios.delete(`/programs/${programId}/users/${userId}/champion`);
  }
}

const championsService = new ChampionsService(client);
export default championsService;
