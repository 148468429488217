import client from './http';

class LocalesService {

  constructor(axios) {
    this.axios = axios;
  }
  // Admin permissions only    
  getLocalesList() {
    return this.axios.get('/locales');
  }

  postCreateLocale(data) {
    return this.axios.post('/locales', data);
  }

  patchUpdateLocale(id, data) {
    return this.axios.patch(`/locales/${id}`, data);
  }

  getLocaleCodes() {
    return this.axios.get('/locales/codes');
  }

}

const localesService = new LocalesService(client);
export default localesService;
