export const eventTriggers = Object.freeze({
  BEFORE: 1,
  ON: 2,
  AFTER: 3
});

export const eventTriggersDisplay = [
  { id: eventTriggers.BEFORE, name: 'Before'},
  { id: eventTriggers.ON, name: 'On'},
  { id: eventTriggers.AFTER, name: 'After'},
];