import { QuestionClass } from '@models/questionClass';
import { QuestionsTranslationsClass } from '@models/questionClass';

const state = () => ({
  questionsList:[],
  question: new QuestionClass(),
  multiChoiceMatchingQuestion: new QuestionClass(),
  parentSourceIndex: undefined,
  parentSourceId: undefined
});

const mutations = {
  ADD_QUESTION (state, data ) {
    state.questionsList.push(data); 
  },
  UPDATE_QUESTION (state, data) {
    state.question = {
      ...state.question,
      ...data
    };
    if(data?.choices?.length > 0) {
      state.question = {
        ...state.question,
        choices: data?.choices?.map((c, i) => ({ ...c, choice_order: i}))
      };
    }
  },
  UPDATE_MATCHING_QUESTION (state, data) {
    state.multiChoiceMatchingQuestion = {
      ...state.multiChoiceMatchingQuestion,
      ...data,
      weight: data.weight
    };
  },
  UPDATE_QUESTION_TRANS (state, trans) {
    const findIndex = state.question.translations.find(t => t.locale_id === trans.locale_id);
    if(findIndex) {
      const newArr = state.question.translations.map(obj => {
        if (obj.locale_id === trans.locale_id) {
          return {...obj, ...trans};
        }
        return obj;
      });
              
      state.question = {
        ...state.question,
        translations: newArr
      };
           
    } else {
      state.question = {
        ...state.question,
        translations: [
          ...state.question.translations,
          new QuestionsTranslationsClass(trans)
        ]
      };
    }
        
  },
  UPDATE_QUESTION_CHOICE_TRANS (state, choice) {
    state.question = {
      ...state.question,
      choices: [
        ...choice
      ]
    };
  },
  UPDATE_QUESTION_STATEMENT_TRANS (state,statement) {
    state.question = {
      ...state.question,
      statements: [
        ...statement
      ]
    };
  },
  UPDATE_MATCHING_QUESTION_TRANS (state, trans) {
    const findIndex = state.multiChoiceMatchingQuestion.translations.find(t => t.locale_id === trans.locale_id);
    if(findIndex) {
      const newArr = state.multiChoiceMatchingQuestion.translations.map(obj => {
        if (obj.locale_id === trans.locale_id) {
          return {...obj, ...trans};
        }
        return obj;
      });
              
      state.multiChoiceMatchingQuestion = {
        ...state.multiChoiceMatchingQuestion,
        translations: newArr
      };
           
    } else {
      state.multiChoiceMatchingQuestion = {
        ...state.multiChoiceMatchingQuestion,
        translations: [
          ...state.multiChoiceMatchingQuestion.translations,
          new QuestionsTranslationsClass(trans)
        ]
      };
    }
        
  },
  UPDATE_MATCHING_QUESTION_CHOICE_TRANS (state, choice) {
    state.multiChoiceMatchingQuestion = {
      ...state.multiChoiceMatchingQuestion,
      choices: [
        ...choice
      ]
    };
  },
  ADD_MATCHED_CHOICES(state, {index, type, choice}) {
    if(type === 'source') {
      state.parentSourceIndex = index;
      state.parentSourceId = choice.id;
    }
    if(type === 'target') {     
      if(state.question.choices?.[state.parentSourceIndex]){
        state.question.choices[state.parentSourceIndex].matched_choices.push({
          parent_index: state.parentSourceIndex,
          choice_order: index
        });
      } else {
        state.question.choices.forEach((c, cIndex) => {
          if(c.id === state.parentSourceIndex) {
            c.matched_choices.push({
              parent_index: state.parentSourceIndex,
              choice_order: index
            });
          }
        });
      }
    }        
  },
  REMOVE_MATCHED_CHOICES(state, {index, type, choice}) {
    if(type === 'target') {
      // Left side of matching choices
      if(state.question.choices?.[state.parentSourceIndex]){
        const choiceIndex = state.question.choices[state.parentSourceIndex].matched_choices.findIndex(m => m.choice_order === index);
        if(choiceIndex !== -1) {
          state.question.choices[state.parentSourceIndex].matched_choices.splice(choiceIndex, 1);
        } else {
          const filterRemovedById = state.question.choices[state.parentSourceIndex].matched_choices.filter(c => c.id !== choice.id);
          state.question.choices[state.parentSourceIndex].matched_choices = filterRemovedById;
        }
      }
      // Right side of matching choices
      if(state.multiChoiceMatchingQuestion.choices){
        const filterChoice = state.multiChoiceMatchingQuestion.choices.findIndex(c => c.id === choice.id);
        if(filterChoice !== -1) {
          const parentId = state.parentSourceId;
          const r = state.multiChoiceMatchingQuestion.choices[filterChoice].matched_choices.filter(mc => mc.id !== parentId);
          state.multiChoiceMatchingQuestion.choices[filterChoice].matched_choices = r;
        }
      }       
    }
  },
  CLEAR_QUESTION (state, data) {
    state.question = new QuestionClass({});
    state.multiChoiceMatchingQuestion = new QuestionClass({});
    state.parentSourceIndex = undefined;
  },
  REMOVE_MANY_MATCHED_CHOICES(state, {type, choice}) {
    state.question.choices = state.question.choices.map(c => ({...c, matched_choices: []}));
    state.multiChoiceMatchingQuestion.choices = state.multiChoiceMatchingQuestion.choices.map(c => ({...c, matched_choices: []}));
  }
};

const getters = { 
  questionList: state => state.questionsList,
  questionFormType: state => ({'text': state.question.target_form_type?.name, 'value': state.question.target_form_type?.id}),
  questionCommon: state => state.question,
  multiChoiceMatchingQuestion: state => state.multiChoiceMatchingQuestion,
  parentSourceIndex: state => state.parentSourceIndex,
  parentSourceId: state => state.parentSourceId

};

export default {
  namespaced: true,           
  mutations,
  state,
  getters
};
