/* eslint-disable camelcase */
import { userStatus }  from './userStatus';
export class UserRolesClass {
  constructor ({
    id,
    client_id = '',
    program_id = '',
    match_limit = 0,
    status_id = userStatus.ACTIVE,
    role_id = '',
  } = {}) {
    this.id = id;
    this.client_id = client_id;
    this.program_id = program_id;
    this.match_limit = match_limit;
    this.status_id = status_id;
    this.role_id = role_id;
  }
}

export class RolesClass {
  constructor ({
    id,
    name = ''
  } = {}) {
    this.id = id;
    this.name = name;
  }
}
