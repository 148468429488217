export const topicChannel = Object.freeze({
  EMAIL:  1,
  SMS: 2,
  PLATFORM_NOTIFICATION: 3
});
    
export const topicChannelDisplay = {
  [topicChannel.EMAIL]: 'Email',
  [topicChannel.SMS]: 'SMS',
  [topicChannel.PLATFORM_NOTIFICATION]: 'Notification'
};

export const topicTypes = Object.freeze({
  MESSAGE: 1,
  INVITE: 2,
  CHAT: 3,
  NOTIFICATION: 4,
  SYSTEM_NOTIFICATION: 5,
  TEST_MESSAGE: 6,
});
      
export const topicTypesDisplay = {
  [topicTypes.MESSAGE]: 'Message',
  [topicTypes.INVITE]: 'Invite',
  [topicTypes.CHAT]: 'Chat',
  [topicTypes.NOTIFICATION]: 'Notification',
};

export const recipientTypes = Object.freeze({
  USER: 'App\\Models\\User',
  GROUPS: 'App\\Models\\Group'
});
    
  