export const resourceTypes = Object.freeze({
  FILE: 1,
  URL: 2,
  WEB_PAGE: 3,
});

export const resourceTypesDisplay = {
  [resourceTypes.FILE]: 'File',
  [resourceTypes.URL]: 'Url',
  [resourceTypes.WEB_PAGE]: 'Web page',
};

export const resourceTypesSelect = [
  { value: [resourceTypes.FILE], text: 'File'},
  { value: [resourceTypes.URL], text: 'Url'},
  { value: [resourceTypes.WEB_PAGE], text: 'Web page'},
];
