import { userRoles } from "@/models/userRoles";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import _uniq from 'lodash/uniq';
import moment from 'moment';

/*
 * @brief: Convert base on timezone of input and browser timezone
 * @example:
 *   input: 2023-06-30T00:00:00.000000Z (Timezone = GMT)
 *   output: 2023-06-29 (Timezone = GMT-7)
 */
export function localeDateStringFromIsoDateTime(isoDateTime, requireTime = false, ) {
  const dt = new Date(isoDateTime);
  if (!isNaN(dt)) {
    if(requireTime) {
      return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}`;
    }
    return dt.toLocaleDateString();
  }
  return null;
}

/*
 * @brief: Convert base on browser timezone, ignore timezone of input
 * @example:
 *   input: 2023-06-30T00:00:00.000000Z (Timezone = GMT)
 *   output: 2023-06-30 (For any timezones)
 */
export function localeDateStringFromIsoDateTimeWithoutTimezone(isoDateTime, requireTime = false) {
  if(!isoDateTime) return;
  const isoDateTimeWithoutTimezone = isoDateTime.replace('Z', '');
  const dt = new Date(isoDateTimeWithoutTimezone);
  if (!isNaN(dt)) {
    if(requireTime) {
      return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}`;
    }
    return dt.toLocaleDateString();
  }
  return null;
}

/*
 * @brief: Convert base on browser timezone, ignore timezone of input
 * @example:
 *   input: 2023-06-30T00:00:00.000000Z (Timezone = GMT)
 *   output: 2023-06-30 (For any timezones)
 */
export function convertStringToDateIgnoreTimezone(isoDateTime, requireTime = false) {
  const isoDateTimeWithoutTimezone = isoDateTime.replace('Z', '');
  const dt = new Date(isoDateTimeWithoutTimezone);
  if (!isNaN(dt)) {
    const year = dt.getFullYear();
    const month = dt.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const day = dt.getDate();
    const formattedDay = day < 10 ? `0${day}` : day;
    return `${year}-${formattedMonth}-${formattedDay}`;
  }
  return null;
}

export function formatDateTimeFromIsoDateTime(isoDateTime, requireTime = false, format = '') {
  if (format) {
    return moment(isoDateTime).format(format);
  }
  return localeDateStringFromIsoDateTime(isoDateTime, requireTime);
}
export const GOODTABLE_ISO_DATETIME_INPUT_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSXXX';
export const GOODTABLE_LOCALISED_DATE_FORMAT = getLocaleDateString();
export const GOODTABLE_LOCALISED_DATE_TIME_FORMAT = `${getLocaleDateString()} HH:mm:ss`;

export const GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT = 'Y-m-d';
export const PRIMARY_DATE_FORMAT = getFlatpickerDateString();

export const PRIMARY_COLOR = "#284036";
export const SECONDARY_COLOR = "#008080";
export const AOM_GREEN_COLOR = "#9bcc65";

export const STATUS_COLOR = {
  Active        : 'light-success',
  Applied       : 'light-primary',
  Suspended     : 'light-warning',
  Rejected      : 'light-danger',
  Inactive      : 'light-info',
  Published     : 'light-success',
  Cancelled     : 'light-danger',
  Started       : 'light-primary',
  Completed     : 'light-success',
  Pending       : 'light-primary',
  Sent          : 'light-success',
  Sending       : 'light-info',
  Failed        : 'light-danger',
  Deleted       : 'light-danger'
};

/**
 * 
 * @param {string} text 
 * @returns {string}
 */
export function toFriendlyUrlPart(text) {
  return text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9\-]|^-+|-+$/g, '');
}

/**
 * 
 * @param {*} param0 The veevalidate validation context
 * @returns {boolean|null}
 */
export function getValidationState({ dirty, validated, valid = null }) {
  return dirty || validated ? valid : null;
}

/**
 * 
 * @param {string|null} msg 
 * @returns {object}
 */

export function makeErrorToast(msg) {
  return {
    component: ToastificationContent,
    position: "top-right",
    props: {
      title: 'Error',
      icon: "AlertCircleIcon",
      variant: "danger",
      text: msg || 'An error has occurred. Please contact support if the problem persists.',
    }
  };
}
export function makeSuccessToast(msg) {
  return {
    component: ToastificationContent,
    position: "top-right",
    props: {
      title: 'Success',
      icon: "CheckIcon",
      variant: "success",
      text: msg || 'Action completed successfully.',
    },
  };
}

/**
 *
 * @param roles: User roles from API
 * @returns id of user programs
 */
export function isValidUserRole(role) {
  const validRoles = Object.values(userRoles);
  if (!role || !validRoles.includes(role)) { return false; }
  return true;
}

/**
 *
 * @param roles: User roles from API
 * @returns { Array } of role names
 */
export function getUserRoles(roles) {
  if (roles && roles.length > 0) {
    const userRoles = roles.map(item => item.role.id);
    return _uniq(userRoles);
  }
  return [];
}

/**
 * Returns the date with added `months` of delay.
 *
 * @param {Number} months - the delay in months
 *
 * @returns {Date}
 */
export function dateWithMonthsDelay (months) {  
  const date = new Date();
  date.setMonth(date.getMonth() + months);
  
  return date;
}

/**
 * Returns the date with years subtracted.
 *
 * @param {Number} years - the delay in years
 *
 * @returns {Date}
 */
export function dateWithYearSubtracted (year) {  
  const date = new Date();
  date.setFullYear(date.getFullYear() - year);
  
  return date;
}

/**
 *
 * @param roles: User roles from API
 * @returns name of the highest role
 */
export function getHighestUserRole(roles) {
  if (roles && roles.length > 0) {
    const roleIds = roles.map(item => item.role.id);
    if (roleIds.includes(userRoles.ADMIN)) { return userRoles.ADMIN; }
    if (roleIds.includes(userRoles.PROGRAM_ADMIN)) { return userRoles.PROGRAM_ADMIN; }
    if (roleIds.includes(userRoles.CHAMPION)) { return userRoles.CHAMPION; }
    if (roleIds.includes(userRoles.MENTOR)) { return userRoles.MENTOR; }
    if (roleIds.includes(userRoles.MENTEE)) { return userRoles.MENTEE; }
    return roleIds[0];
  }
  return "guest";
}

/**
 *
 * @param roles: User roles from API
 * @returns id of user programs
 */
export function getProgramsFromUserRoles(roles) {
  if (roles && roles.length > 0) {
    return roles.map(item => item.program_id).filter(program => !!program);
  }
  return [];
}

/**
 *
 * @param permissions: Spatie permissions
 * @returns CASL abilities
 */
export function parseAbilitiesFromPermissions({id, roles, permissions}) {
  if (roles.includes(userRoles.ADMIN)) {
    return [
      {
        action: "manage",
        subject: "all",
      },
      // {
      //     inverted: true,
      //     action: "read",
      //     subject: "ChampionNavs",
      // },
      // {
      //     inverted: true,
      //     action: "read",
      //     subject: "ParticipantNavs",
      // },
    ];
  }

  if (roles.includes(userRoles.CHAMPION) || roles.includes(userRoles.PROGRAM_ADMIN)) {
    return [
      // FIXME: Temporarily allow champion manage all. Change it base on permission later
      {
        action: "manage",
        subject: "all",
      },
      {
        inverted: true,
        action: "read",
        subject: "AdminRoutes",
      },
      // {
      //     inverted: true,
      //     action: "read",
      //     subject: "AdminNavs",
      // },
      // {
      //     inverted: true,
      //     action: "read",
      //     subject: "ParticipantNavs",
      // },
    ];
  }

  if (roles.includes(userRoles.MENTOR) || roles.includes(userRoles.MENTEE) || roles.includes(userRoles.PARTICIPANT_CANDIDATE)) {
    const abilities = permissions.map(permission => {
      let rule = '' + permission.name;
      const isOwn = rule.includes('own');
      // Remove text .own from rule
      if (isOwn) {
        rule = rule.replace('.own', '');
      }
      const keywords = rule.split('.');
      const action = keywords.pop();
      const subject = keywords.join('.');

      const ability = {
        action,
        subject
      };
      if (isOwn) {
        ability.conditions = { user_id: id };
      }
      return ability;
    });
    const additionalAbilities = [];
    if (roles.includes(userRoles.MENTOR)) {
      additionalAbilities.push({
        action: "read",
        subject: "MentorNavs"
      });
    }
    if (roles.includes(userRoles.MENTEE)) {
      additionalAbilities.push({
        action: "read",
        subject: "MenteeNavs"
      });
    }
    return [
      ...abilities,
      {
        action: "read",
        subject: "Auth",
      },
      // Show ParticipantNavs for participants
      {
        action: "read",
        subject: "ParticipantNavs",
      },
      ...additionalAbilities
    ];
  }
  // Guest can only read public pages
  return [
    {
      action: "read",
      subject: "Auth",
    },
    // Show ParticipantNavs for participants
    {
      action: "read",
      subject: "ParticipantNavs",
    },
  ];
}


/**
 * Shade or blend a colour
 * https://stackoverflow.com/a/13542669
 * @param {number} p percent
 * @param {string} c0 first colour
 * @param {string|false} c1 second colour
 * @param {boolean} l true for linear blending, false for log
 * @returns {string|null}
 */

export const pSBC = (p, c0, c1, l) => {
  let pSBCr = null;
  let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof (c1) == "string";
  if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null;
  if (!pSBCr) pSBCr = d => {
    let n = d.length, x = {};
    if (n > 9) {
      [r, g, b, a] = d = d.split(","), n = d.length;
      if (n < 3 || n > 4) return null;
      x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1;
    } else {
      if (n == 8 || n == 6 || n < 4) return null;
      if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
      d = i(d.slice(1), 16);
      if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
      else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1;
    } return x;
  };
  h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
  if (!f || !t) return null;
  if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
  else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
  a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
  if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
  else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2);
};

export const setRootElementCssVars = (primary, secondary) => {
  document.documentElement.style = `--aom-color-primary: ${primary}; --aom-color-secondary: ${secondary}`;
};
export function getLocaleDateString() {
  const formats = {
    "af-ZA": "yyyy/MM/dd",
    "am-ET": "d/M/yyyy",
    "ar-AE": "dd/MM/yyyy",
    "ar-BH": "dd/MM/yyyy",
    "ar-DZ": "dd-MM-yyyy",
    "ar-EG": "dd/MM/yyyy",
    "ar-IQ": "dd/MM/yyyy",
    "ar-JO": "dd/MM/yyyy",
    "ar-KW": "dd/MM/yyyy",
    "ar-LB": "dd/MM/yyyy",
    "ar-LY": "dd/MM/yyyy",
    "ar-MA": "dd-MM-yyyy",
    "ar-OM": "dd/MM/yyyy",
    "ar-QA": "dd/MM/yyyy",
    "ar-SA": "dd/MM/yy",
    "ar-SY": "dd/MM/yyyy",
    "ar-TN": "dd-MM-yyyy",
    "ar-YE": "dd/MM/yyyy",
    "arn-CL": "dd-MM-yyyy",
    "as-IN": "dd-MM-yyyy",
    "az-Cyrl-AZ": "dd.MM.yyyy",
    "az-Latn-AZ": "dd.MM.yyyy",
    "ba-RU": "dd.MM.yy",
    "be-BY": "dd.MM.yyyy",
    "bg-BG": "dd.M.yyyy",
    "bn-BD": "dd-MM-yy",
    "bn-IN": "dd-MM-yy",
    "bo-CN": "yyyy/M/d",
    "br-FR": "dd/MM/yyyy",
    "bs-Cyrl-BA": "d.M.yyyy",
    "bs-Latn-BA": "d.M.yyyy",
    "ca-ES": "dd/MM/yyyy",
    "co-FR": "dd/MM/yyyy",
    "cs-CZ": "d.M.yyyy",
    "cy-GB": "dd/MM/yyyy",
    "da-DK": "dd-MM-yyyy",
    "de-AT": "dd.MM.yyyy",
    "de-CH": "dd.MM.yyyy",
    "de-DE": "dd.MM.yyyy",
    "de-LI": "dd.MM.yyyy",
    "de-LU": "dd.MM.yyyy",
    "dsb-DE": "d. M. yyyy",
    "dv-MV": "dd/MM/yy",
    "el-GR": "d/M/yyyy",
    "en-029": "MM/dd/yyyy",
    "en-AU": "dd/MM/yyyy",
    "en-BZ": "dd/MM/yyyy",
    "en-CA": "dd/MM/yyyy",
    "en-GB": "dd/MM/yyyy",
    "en-IE": "dd/MM/yyyy",
    "en-IN": "dd-MM-yyyy",
    "en-JM": "dd/MM/yyyy",
    "en-MY": "d/M/yyyy",
    "en-NZ": "d/MM/yyyy",
    "en-PH": "M/d/yyyy",
    "en-SG": "d/M/yyyy",
    "en-TT": "dd/MM/yyyy",
    "en-US": "M/d/yyyy",
    "en-ZA": "yyyy/MM/dd",
    "en-ZW": "M/d/yyyy",
    "es-AR": "dd/MM/yyyy",
    "es-BO": "dd/MM/yyyy",
    "es-PE": "dd/MM/yyyy",
    "es-PY": "dd/MM/yyyy",
    "es-US": "M/d/yyyy",
    "es-VE": "dd/MM/yyyy",
    "et-EE": "d.MM.yyyy",
    "eu-ES": "yyyy/MM/dd",
    "fa-IR": "MM/dd/yyyy",
    "fi-FI": "d.M.yyyy",
    "fil-PH": "M/d/yyyy",
    "fo-FO": "dd-MM-yyyy",
    "fr-BE": "d/MM/yyyy",
    "fr-CA": "yyyy-MM-dd",
    "fr-CH": "dd.MM.yyyy",
    "fr-FR": "dd/MM/yyyy",
    "fr-LU": "dd/MM/yyyy",
    "fr-MC": "dd/MM/yyyy",
    "fy-NL": "d-M-yyyy",
    "ga-IE": "dd/MM/yyyy",
    "gd-GB": "dd/MM/yyyy",
    "gl-ES": "dd/MM/yy",
    "gsw-FR": "dd/MM/yyyy",
    "gu-IN": "dd-MM-yy",
    "ha-Latn-NG": "d/M/yyyy",
    "he-IL": "dd/MM/yyyy",
    "hi-IN": "dd-MM-yyyy",
    "hr-BA": "d.M.yyyy.",
    "hr-HR": "d.M.yyyy",
    "hsb-DE": "d. M. yyyy",
    "hu-HU": "yyyy. MM. dd.",
    "hy-AM": "dd.MM.yyyy",
    "id-ID": "dd/MM/yyyy",
    "ig-NG": "d/M/yyyy",
    "ii-CN": "yyyy/M/d",
    "is-IS": "d.M.yyyy",
    "it-CH": "dd.MM.yyyy",
    "it-IT": "dd/MM/yyyy",
    "iu-Cans-CA": "d/M/yyyy",
    "iu-Latn-CA": "d/MM/yyyy",
    "ja-JP": "yyyy/MM/dd",
    "ka-GE": "dd.MM.yyyy",
    "kk-KZ": "dd.MM.yyyy",
    "kl-GL": "dd-MM-yyyy",
    "km-KH": "yyyy-MM-dd",
    "kn-IN": "dd-MM-yy",
    "ko-KR": "yyyy. MM. dd",
    "kok-IN": "dd-MM-yyyy",
    "ky-KG": "dd.MM.yy",
    "lb-LU": "dd/MM/yyyy",
    "lo-LA": "dd/MM/yyyy",
    "lt-LT": "yyyy.MM.dd",
    "lv-LV": "yyyy.MM.dd.",
    "mi-NZ": "dd/MM/yyyy",
    "mk-MK": "dd.MM.yyyy",
    "ml-IN": "dd-MM-yy",
    "mn-MN": "yy.MM.dd",
    "mn-Mong-CN": "yyyy/M/d",
    "moh-CA": "M/d/yyyy",
    "mr-IN": "dd-MM-yyyy",
    "ms-BN": "dd/MM/yyyy",
    "ms-MY": "dd/MM/yyyy",
    "mt-MT": "dd/MM/yyyy",
    "nb-NO": "dd.MM.yyyy",
    "ne-NP": "M/d/yyyy",
    "nl-BE": "d/MM/yyyy",
    "nl-NL": "d-M-yyyy",
    "nn-NO": "dd.MM.yyyy",
    "nso-ZA": "yyyy/MM/dd",
    "oc-FR": "dd/MM/yyyy",
    "or-IN": "dd-MM-yy",
    "pa-IN": "dd-MM-yy",
    "pl-PL": "dd.MM.yyyy",
    "prs-AF": "dd/MM/yy",
    "ps-AF": "dd/MM/yy",
    "pt-BR": "d/M/yyyy",
    "pt-PT": "dd-MM-yyyy",
    "qut-GT": "dd/MM/yyyy",
    "quz-BO": "dd/MM/yyyy",
    "quz-EC": "dd/MM/yyyy",
    "quz-PE": "dd/MM/yyyy",
    "rm-CH": "dd/MM/yyyy",
    "ro-RO": "dd.MM.yyyy",
    "ru-RU": "dd.MM.yyyy",
    "rw-RW": "M/d/yyyy",
    "sa-IN": "dd-MM-yyyy",
    "sah-RU": "MM.dd.yyyy",
    "se-FI": "d.M.yyyy",
    "se-NO": "dd.MM.yyyy",
    "se-SE": "yyyy-MM-dd",
    "si-LK": "yyyy-MM-dd",
    "sk-SK": "d. M. yyyy",
    "sl-SI": "d.M.yyyy",
    "sma-NO": "dd.MM.yyyy",
    "sma-SE": "yyyy-MM-dd",
    "smj-NO": "dd.MM.yyyy",
    "smj-SE": "yyyy-MM-dd",
    "smn-FI": "d.M.yyyy",
    "sms-FI": "d.M.yyyy",
    "sq-AL": "yyyy-MM-dd",
    "sr-Cyrl-BA": "d.M.yyyy",
    "sr-Cyrl-CS": "d.M.yyyy",
    "sr-Cyrl-ME": "d.M.yyyy",
    "sr-Cyrl-RS": "d.M.yyyy",
    "sr-Latn-BA": "d.M.yyyy",
    "sr-Latn-CS": "d.M.yyyy",
    "sr-Latn-ME": "d.M.yyyy",
    "sr-Latn-RS": "d.M.yyyy",
    "sv-FI": "d.M.yyyy",
    "sv-SE": "yyyy-MM-dd",
    "sw-KE": "M/d/yyyy",
    "syr-SY": "dd/MM/yyyy",
    "ta-IN": "dd-MM-yyyy",
    "te-IN": "dd-MM-yy",
    "tg-Cyrl-TJ": "dd.MM.yy",
    "th-TH": "d/M/yyyy",
    "tk-TM": "dd.MM.yy",
    "tn-ZA": "yyyy/MM/dd",
    "tr-TR": "dd.MM.yyyy",
    "tt-RU": "dd.MM.yyyy",
    "tzm-Latn-DZ": "dd-MM-yyyy",
    "ug-CN": "yyyy-M-d",
    "uk-UA": "dd.MM.yyyy",
    "ur-PK": "dd/MM/yyyy",
    "uz-Cyrl-UZ": "dd.MM.yyyy",
    "uz-Latn-UZ": "dd/MM yyyy",
    "vi-VN": "dd/MM/yyyy",
    "wo-SN": "dd/MM/yyyy",
    "xh-ZA": "yyyy/MM/dd",
    "yo-NG": "d/M/yyyy",
    "zh-CN": "yyyy/M/d",
    "zh-HK": "d/M/yyyy",
    "zh-MO": "d/M/yyyy",
    "zh-SG": "d/M/yyyy",
    "zh-TW": "yyyy/M/d",
    "zu-ZA": "yyyy/MM/dd",
  };

  return formats[navigator.language] || "dd/MM/yyyy";
}

export function getFlatpickerDateString() {
  const formats = {
    "af-ZA": "Y/m/d",
    "am-ET": "j/n/Y",
    "ar-AE": "d/m/Y",
    "ar-BH": "d/m/Y",
    "ar-DZ": "d-m-Y",
    "ar-EG": "d/m/Y",
    "ar-IQ": "d/m/Y",
    "ar-JO": "d/m/Y",
    "ar-KW": "d/m/Y",
    "ar-LB": "d/m/Y",
    "ar-LY": "d/m/Y",
    "ar-MA": "d-m-Y",
    "ar-OM": "d/m/Y",
    "ar-QA": "d/m/Y",
    "ar-SA": "d/m/y",
    "ar-SY": "d/m/Y",
    "ar-TN": "d-m-Y",
    "ar-YE": "d/m/Y",
    "arn-CL": "d-m-Y",
    "as-IN": "d-m-Y",
    "az-Cyrl-AZ": "d.m.Y",
    "az-Latn-AZ": "d.m.Y",
    "ba-RU": "d.m.y",
    "be-BY": "d.m.Y",
    "bg-BG": "d.n.Y",
    "bn-BD": "d-m-y",
    "bn-IN": "d-m-y",
    "bo-CN": "Y/n/j",
    "br-FR": "d/m/Y",
    "bs-Cyrl-BA": "j.n.Y",
    "bs-Latn-BA": "j.n.Y",
    "ca-ES": "d/m/Y",
    "co-FR": "d/m/Y",
    "cs-CZ": "j.n.Y",
    "cy-GB": "d/m/Y",
    "da-DK": "d-m-Y",
    "de-AT": "d.m.Y",
    "de-CH": "d.m.Y",
    "de-DE": "d.m.Y",
    "de-LI": "d.m.Y",
    "de-LU": "d.m.Y",
    "dsb-DE": "j. n. Y",
    "dv-MV": "d/m/y",
    "el-GR": "j/n/Y",
    "en-029": "m/d/Y",
    "en-AU": "d/m/Y",
    "en-BZ": "d/m/Y",
    "en-CA": "d/m/Y",
    "en-GB": "d/m/Y",
    "en-IE": "d/m/Y",
    "en-IN": "d-m-Y",
    "en-JM": "d/m/Y",
    "en-MY": "j/n/Y",
    "en-NZ": "j/m/Y",
    "en-PH": "n/j/Y",
    "en-SG": "j/n/Y",
    "en-TT": "d/m/Y",
    "en-US": "n/j/Y",
    "en-ZA": "Y/m/d",
    "en-ZW": "n/j/Y",
    "es-AR": "d/m/Y",
    "es-BO": "d/m/Y",
    "es-PE": "d/m/Y",
    "es-PY": "d/m/Y",
    "es-US": "n/j/Y",
    "es-VE": "d/m/Y",
    "et-EE": "j.m.Y",
    "eu-ES": "Y/m/d",
    "fa-IR": "m/d/Y",
    "fi-FI": "j.n.Y",
    "fil-PH": "n/j/Y",
    "fo-FO": "d-m-Y",
    "fr-BE": "j/m/Y",
    "fr-CA": "Y-m-d",
    "fr-CH": "d.m.Y",
    "fr-FR": "d/m/Y",
    "fr-LU": "d/m/Y",
    "fr-MC": "d/m/Y",
    "fy-NL": "j-n-Y",
    "ga-IE": "d/m/Y",
    "gd-GB": "d/m/Y",
    "gl-ES": "d/m/y",
    "gsw-FR": "d/m/Y",
    "gu-IN": "d-m-y",
    "ha-Latn-NG": "j/n/Y",
    "he-IL": "d/m/Y",
    "hi-IN": "d-m-Y",
    "hr-BA": "j.n.Y.",
    "hr-HR": "j.n.Y",
    "hsb-DE": "j. n. Y",
    "hu-HU": "Y. m. d.",
    "hy-AM": "d.m.Y",
    "id-ID": "d/m/Y",
    "ig-NG": "j/n/Y",
    "ii-CN": "Y/n/j",
    "is-IS": "j.n.Y",
    "it-CH": "d.m.Y",
    "it-IT": "d/m/Y",
    "iu-Cans-CA": "j/n/Y",
    "iu-Latn-CA": "j/m/Y",
    "ja-JP": "Y/m/d",
    "ka-GE": "d.m.Y",
    "kk-KZ": "d.m.Y",
    "kl-GL": "d-m-Y",
    "km-KH": "Y-m-d",
    "kn-IN": "d-m-y",
    "ko-KR": "Y. m. d",
    "kok-IN": "d-m-Y",
    "ky-KG": "d.m.y",
    "lb-LU": "d/m/Y",
    "lo-LA": "d/m/Y",
    "lt-LT": "Y.m.d",
    "lv-LV": "Y.m.d.",
    "mi-NZ": "d/m/Y",
    "mk-MK": "d.m.Y",
    "ml-IN": "d-m-y",
    "mn-MN": "y.m.d",
    "mn-Mong-CN": "Y/n/j",
    "moh-CA": "n/j/Y",
    "mr-IN": "d-m-Y",
    "ms-BN": "d/m/Y",
    "ms-MY": "d/m/Y",
    "mt-MT": "d/m/Y",
    "nb-NO": "d.m.Y",
    "ne-NP": "n/j/Y",
    "nl-BE": "j/m/Y",
    "nl-NL": "j-n-Y",
    "nn-NO": "d.m.Y",
    "nso-ZA": "Y/m/d",
    "oc-FR": "d/m/Y",
    "or-IN": "d-m-y",
    "pa-IN": "d-m-y",
    "pl-PL": "d.m.Y",
    "prs-AF": "d/m/y",
    "ps-AF": "d/m/y",
    "pt-BR": "j/n/Y",
    "pt-PT": "d-m-Y",
    "qut-GT": "d/m/Y",
    "quz-BO": "d/m/Y",
    "quz-EC": "d/m/Y",
    "quz-PE": "d/m/Y",
    "rm-CH": "d/m/Y",
    "ro-RO": "d.m.Y",
    "ru-RU": "d.m.Y",
    "rw-RW": "n/j/Y",
    "sa-IN": "d-m-Y",
    "sah-RU": "m.d.Y",
    "se-FI": "j.n.Y",
    "se-NO": "d.m.Y",
    "se-SE": "Y-m-d",
    "si-LK": "Y-m-d",
    "sk-SK": "j. n. Y",
    "sl-SI": "j.n.Y",
    "sma-NO": "d.m.Y",
    "sma-SE": "Y-m-d",
    "smj-NO": "d.m.Y",
    "smj-SE": "Y-m-d",
    "smn-FI": "j.n.Y",
    "sms-FI": "j.n.Y",
    "sq-AL": "Y-m-d",
    "sr-Cyrl-BA": "j.n.Y",
    "sr-Cyrl-CS": "j.n.Y",
    "sr-Cyrl-ME": "j.n.Y",
    "sr-Cyrl-RS": "j.n.Y",
    "sr-Latn-BA": "j.n.Y",
    "sr-Latn-CS": "j.n.Y",
    "sr-Latn-ME": "j.n.Y",
    "sr-Latn-RS": "j.n.Y",
    "sv-FI": "j.n.Y",
    "sv-SE": "Y-m-d",
    "sw-KE": "n/j/Y",
    "syr-SY": "d/m/Y",
    "ta-IN": "d-m-Y",
    "te-IN": "d-m-y",
    "tg-Cyrl-TJ": "d.m.y",
    "th-TH": "j/n/Y",
    "tk-TM": "d.m.y",
    "tn-ZA": "Y/m/d",
    "tr-TR": "d.m.Y",
    "tt-RU": "d.m.Y",
    "tzm-Latn-DZ": "d-m-Y",
    "ug-CN": "Y-n-j",
    "uk-UA": "d.m.Y",
    "ur-PK": "d/m/Y",
    "uz-Cyrl-UZ": "d.m.Y",
    "uz-Latn-UZ": "d/m Y",
    "vi-VN": "d/m/Y",
    "wo-SN": "d/m/Y",
    "xh-ZA": "Y/m/d",
    "yo-NG": "j/n/Y",
    "zh-CN": "Y/n/j",
    "zh-HK": "j/n/Y",
    "zh-MO": "j/n/Y",
    "zh-SG": "j/n/Y",
    "zh-TW": "Y/n/j",
    "zu-ZA": "Y/m/d",
  };

  return formats[navigator.language] || "d/m/Y";
}

export function setCookie(name, value, expires, domain, path) {
  const cookie = name + '=' + encodeURIComponent(value) +
              ((expires) ? '; expires=' + expires : '') +
              ((domain) ? '; domain=' + domain : '') +
              ((path) ? '; path=' + path : '');
  document.cookie = cookie;
}

export function getCookieValue(cookieName) {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(';');

  for (var i = 0; i < cookieArray.length; i++) {
    var cookie = cookieArray[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}

export function convertModelToFormData(model, form, namespace) {
  let formData = form || new FormData();

  for (let propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date) {
      formData.append(formKey, model[propertyName].toISOString());
    } else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element, index) => {
        const tempFormKey = `${formKey}[${index}]`;
        convertModelToFormData(element, formData, tempFormKey);
      });
    } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
      convertModelToFormData(model[propertyName], formData, formKey);
    } else if (typeof model[propertyName] === 'object' && (model[propertyName] instanceof File)) {
      formData.append(formKey, model[propertyName]);
    } else {
      formData.append(formKey, model[propertyName].toString());
    }
  }
  return formData;
}

export function getFormatedStringFromDays(numberOfDays) {
  let formatedString = '';
  const weeks = Math.floor(numberOfDays / 7);
  const days = numberOfDays % 7;

  if (weeks) {
    formatedString += `${weeks}w `;
  }
  if (days) {
    formatedString += `${days}d`;
  }
  return formatedString;
}

export function validateEmailToken(to, from, next) {
  if (!/^([a-zA-Z0-9]){50}$/.test(to?.params?.token)){
    return next({name: 'error-404'});
  }
  return next();
}

export function convertLinksToATags(text) {
  if (!text) { return ''; }
  let newText = text + '';
  const links = newText.match(/(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
  if (links) {
    for (const link of links) {
      newText = newText.replace(link, `<a href="${link}" target="_blank">${link}</a>`);
    }
  }
  return newText;
}

export function localDateStringOrDateRangeStringToUtcRange(localDateString)
{
  let startDate = null;
  let endDate = null;
  const dateRange = localDateString.split(" to ");
  if (dateRange.length > 1) {
    startDate = new Date(dateRange[0]);
    endDate = new Date(dateRange[1]);
  } else {
    startDate = new Date(dateRange[0]);
    endDate = new Date(dateRange[0]);
  }

  if (!startDate || !endDate) {
    return null;
  }

  startDate.setHours(0, 0, 0);
  endDate.setHours(23, 59, 59);
  return [startDate.toISOString(), endDate.toISOString()];
}

export function dateObjectRangeToIsoString(from, to) {
  from.setHours(0,0,0);
  to.setHours(23,59,59);
  return [from.toISOString(), to.toISOString()];
}

export function localDateAndTimeToUtc(date, time)
{
  const dt = new Date(date);
  const timeParts = time.split(':');
  dt.setHours(timeParts[0] || 0, timeParts[1] || 0, timeParts[2] || 0);
  return dt.toISOString();
}