export const messageStatus = Object.freeze({
  SENDING:  1,
  SENT: 2,
  FAILED: 3,
  PENDING: 4
});
      
export const messageStatusDisplay = {
  [messageStatus.SENDING]: 'Sending',
  [messageStatus.SENT]: 'Sent',
  [messageStatus.FAILED]: 'Failed',
  [messageStatus.PENDING]: 'Pending'
};

// Vue goodtable select dropdown
export const messageStatusSelectDisplay = Object.entries(messageStatusDisplay).map(([key, val]) => ({ value: key, text: val }));

    