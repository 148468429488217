/* eslint-disable camelcase */
import { programStatus, programTypes } from '@models';
import {
  dateWithMonthsDelay
} from "@/libs/utils";

export class ProgramClass {
  constructor ({
    id = undefined,
    client_id = undefined,
    type_id = programTypes.STANDARD,
    name = undefined,
    indexed = false,
    path = undefined,
    time_zone = undefined,
    theme_primary_color = '#002a54',
    theme_secondary_color = '#93c463',
    licence_start_date = dateWithMonthsDelay(1),
    licence_end_date = dateWithMonthsDelay(7),
    match_duration_value = 6,
    match_duration_unit = 3,
    match_request_duration_value = 1,
    match_request_duration_unit = 2,
    active_participant_limit = 0,
    participant_visibility = 0,
    multiple_roles_enabled = 0,
    email_signature = undefined,
    wcag_compliance_id = 1,
    status_id = programStatus.ACTIVE,
    partner_pref_active = undefined,
    partner_pref_weight = undefined,
    champion_matching_enabled = undefined,
    program_saml = {},
    header_code = undefined,
    footer_code = undefined,
    created_at = undefined
  } = {}) {
    this.id = id;
    this.name = name;
    this.indexed = indexed;
    this.client_id = client_id;
    this.type_id = type_id;
    this.path = path;
    this.time_zone = time_zone;
    this.theme_primary_color = theme_primary_color;
    this.theme_secondary_color = theme_secondary_color;
    this.licence_start_date = licence_start_date;
    this.licence_end_date = licence_end_date;
    this.match_duration_value = match_duration_value;
    this.match_duration_unit = match_duration_unit;
    this.match_request_duration_value = match_request_duration_value;
    this.match_request_duration_unit = match_request_duration_unit;
    this.active_participant_limit = active_participant_limit;
    this.participant_visibility = participant_visibility;
    this.multiple_roles_enabled = multiple_roles_enabled;
    this.email_signature = email_signature;
    this.wcag_compliance_id = wcag_compliance_id;
    this.status_id = status_id;
    this.partner_pref_active = partner_pref_active;
    this.partner_pref_weight = partner_pref_weight;
    this.champion_matching_enabled = champion_matching_enabled;
    this.header_code = header_code;
    this.footer_code = footer_code;
    this.program_saml = program_saml || {};
    this.created_at = created_at;
      
  }
}

export class ProgramSamlClass {
  constructor ({
    id = undefined,
    program_id = undefined,
    client_id = undefined,
    is_enabled = false,
    create_participant = false,
    sso_url = undefined,
    issuers = [],
    created_at = undefined
  } = {}) {
    this.id = id;
    this.program_id = program_id;
    this.client_id = client_id;
    this.is_enabled = is_enabled;
    this.create_participant = create_participant;
    this.sso_url = sso_url;
    this.issuers = issuers.map(i => new SamlIssuerClass(i));
    this.created_at = created_at;
  }
}

export class SamlIssuerClass {
  constructor ({
    id = undefined,
    program_saml_id = undefined,
    entity_id = undefined,
    x509_cert = undefined,
    created_at = undefined
  } = {}) {
    this.id = id;
    this.program_saml_id = program_saml_id;
    this.entity_id = entity_id;
    this.x509_cert = x509_cert;
    this.created_at = created_at;
  }
}

export class ProgramLogoClass {
  constructor ({
    id = undefined,
    program_id = undefined,
    type_id = undefined,
    filename = undefined,
    url = undefined,
    display_order = 0,
    file = undefined,
    link = '',
    client_id = undefined,
    created_at = undefined
  } = {}) {
    this.id = id;
    this.program_id = program_id;
    this.type_id = type_id;
    this.filename = filename;
    this.url = url;
    this.file = file;
    this.link = link;
    this.display_order = display_order;
    this.client_id = client_id;
    this.created_at = created_at;
  }
}
export class ProgramMatches {
  constructor ({
    id = undefined,
    program_id = undefined,
    status_id = undefined,
    pool_id = undefined,
    compatibility = undefined,
    started_at = undefined,
    ended_at = undefined,
    closed_by_id = undefined,
    closing_note = undefined,
    match_users = [],
    created_at = undefined
  } = {}) {
    this.status_id = status_id;
    this.pool_id = pool_id;
    this.program_id = program_id;
    this.compatibility = compatibility;
    this.match_users = match_users.map(m => new MatchUsers(m));
    this.started_at = started_at;
    this.ended_at = ended_at;
    this.closed_by_id = closed_by_id;
    this.closing_note = closing_note;
    this.created_at = created_at;
  }  
}

export class MatchUsers {
  constructor ({
    id = undefined,
    match_id = undefined,
    user_id = undefined,
    role_id = undefined,
    client_id = undefined,
    created_at = undefined
  } = {}) {
    this.id = id;
    this.match_id = match_id;
    this.user_id = user_id;
    this.role_id = role_id;
    this.client_id = client_id;
    this.created_at = created_at;
  }
}
