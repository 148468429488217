import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import translations from './modules/translations/translations';
import questions from './modules/questions/questions';
import participants from './modules/participants';
import profile from './modules/profile';
import programs from './modules/programs/programs';
import champions from './modules/champions';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    translations,
    questions,
    champions,
    participants,
    profile,
    programs
  },
  strict: process.env.DEV,
});
