import { userRoles } from '@/models';
import { validateEmailToken } from '@/libs/utils';

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      publicRoute: true
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      publicRoute: true
    },
  },
  // Participant portal routes
  {
    path: '/:programPath',
    name: 'participant-landing-page',
    component: () => import('@/participants/LandingPage.vue'),
    meta: {
      layout: 'participant',
      resource: 'Auth',
      publicRoute: true
    },
    props: route => ({ programPath: route.params.programPath, subdomain: window.location.host.split('.').slice(0, -2).join('.') })
  },
  {
    path: '/resources/:resource/:token',
    name: 'resource-public-page',
    component: () => import('@/participants/resources/ResourceDetail.vue'),
    meta: {
      layout: 'participant',
      publicRoute: true
    },
    props: route => ({ token: route.params.token }),
    beforeEnter: function (to, from, next) {
      if (to?.params?.token === 'public') {
        return next();
      }
      return validateEmailToken(to, from, next);
    }
  },
  {
    path: '/:clientSlug/:programPath/resources/:resource/:token',
    name: 'program-resource-public-page',
    component: () => import('@/participants/resources/ResourceDetail.vue'),
    meta: {
      layout: 'participant',
      publicRoute: true
    },
    props: route => ({ token: route.params.token }),
    // beforeEnter: validateEmailToken
    beforeEnter: function (to, from, next) {
      if (to?.params?.token === 'public') {
        return next();
      }
      return validateEmailToken(to, from, next);
    }
  },
  {
    path: '/:clientSlug/:programPath/terms',
    name: 'participant-terms',
    component: () => import('@/participants/Terms.vue'),
    meta: {
      pageTitle: 'Terms of Acceptable Use',
      layout: 'participant-then-vertical',// Layout participant for logged out user, layout vertical for logged in
      resource: 'Auth',
      publicRoute: true
    },
  },

  {
    path: '/:clientSlug/:programPath/privacy',
    name: 'participant-privacy',
    component: () => import('@/participants/Privacy.vue'),
    meta: {
      pageTitle: 'Art of Mentoring Privacy Policy',
      layout: 'participant-then-vertical',
      resource: 'Auth',
      publicRoute: true
    }
  },
  {
    path: '/:clientSlug/:programPath/login',
    name: 'participant-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'participant',
      resource: 'Auth',
      publicRoute: true,
    }
  },
  {
    path: '/:clientSlug/:programPath/forgot-password/:programId',
    name: 'participant-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    props: true,
    meta: {
      layout: 'participant',
      resource: 'Auth',
      publicRoute: true,
    },
  },
  {
    path: '/:clientSlug/:programPath/reset-password/:token',
    name: 'participant-reset-password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      publicRoute: true
    },
  },
  {
    path: '/:clientSlug/:programPath/register',
    name: 'participant-register',
    component: () => import('@/participants/Register.vue'),
    meta: {
      layout: 'participant',
      resource: 'Auth',
      publicRoute: true
    },
  },
  {
    path: '/:clientSlug/:programPath/users/:userId/verify/:token',
    name: 'participant-verify',
    component: () => import('@/participants/EmailVerification.vue'),
    meta: {
      layout: 'participant',
      resource: 'Auth',
      publicRoute: true
    },
  },
  {
    path: '/:clientSlug/:programPath/home',
    name: 'participant-home',
    component: () => import('@/participants/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        }
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/select-program-role',
    name: 'participant-select-role',
    component: () => import('@/participants/SelectProgramRole.vue'),
    meta: {
      pageTitle: 'Select Role',
    }
  },
  {
    path: '/:clientSlug/:programPath/applications',
    name: 'participant-applications-list',
    component: () => import('@/participants/applications/ApplicationsList.vue'),
    meta: {
      pageTitle: 'My applications',
      breadcrumb: [
        {
          text: 'My applications',
          active: true,
        }
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: `/:clientSlug/:programPath/application/:role/question/:questionId/enrolement`,
    name: 'participant-application-mentor-enrolement',
    component: () => import('@/participants/dashboard/ApplicationEditStep.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Application',
          active: true,
        }
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: `/:clientSlug/:programPath/application/${userRoles.MENTOR}/question/:questionId`,
    name: 'participant-application-mentor',
    component: () => import('@/participants/dashboard/ApplicationEditStep.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Application',
          active: true,
        },
        {
          text: 'Mentor',
          active: true,
        }
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: `/:clientSlug/:programPath/application/${userRoles.MENTEE}/question/:questionId`,
    name: 'participant-application-mentee',
    component: () => import('@/participants/dashboard/ApplicationEditStep.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Application',
          active: true,
        },
        {
          text: 'Mentee',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/application/'+userRoles.MENTEE+'/overview',
    name: 'participant-application-mentee-overview',
    component: () => import('@/participants/dashboard/ApplicationOverview.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'My Application',
          active: true,
        },
        {
          text: 'Mentee',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/application/'+userRoles.MENTOR+'/overview',
    name: 'participant-application-mentor-overview',
    component: () => import('@/participants/dashboard/ApplicationOverview.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'My Application',
          active: true,
        },
        {
          text: 'Mentor',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/application/:role/questions/:questionId',
    name: 'participant-application-edit',
    component: () => import('@/participants/dashboard/ApplicationEdit.vue'),
    meta: {
      pageTitle: 'Application Edit',
      breadcrumb: [
        {
          text: 'Application',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/application/:role/complete',
    name: 'participant-application-complete',
    component: () => import('@/participants/dashboard/ApplicationComplete.vue'),
    meta: {
      pageTitle: 'Complete your Profile',
      breadcrumb: [
        {
          text: 'Profile',
        },
        {
          text: 'Complete',
          active: true,
        }
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/surveys',
    name: 'participant-surveys',
    component: () => import('@/participants/surveys/Surveys.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Surveys',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/surveys/:form/:token',
    name: 'participant-survey-public-overview',
    component: () => import('@/participants/surveys/SurveysPublicForm.vue'),
    meta: {
      pageTitle: 'Survey Public Overview',
      breadcrumb: [
        {
          text: 'Survey',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
      publicRoute: true,
      layout: 'participant',
    },
    props: route => ({ token: route.params.token }),
    beforeEnter: validateEmailToken
  },
  {
    path: '/:clientSlug/:programPath/surveys/:form/matches/:match/:token',
    name: 'participant-match-survey-public-overview',
    component: () => import('@/participants/surveys/SurveysPublicForm.vue'),
    meta: {
      pageTitle: 'Survey Public Overview',
      breadcrumb: [
        {
          text: 'Survey',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
      publicRoute: true,
      layout: 'participant',
    },
    props: route => ({ token: route.params.token }),
    beforeEnter: validateEmailToken
  },
  {
    path: '/:clientSlug/:programPath/surveys/:form',
    name: 'participant-survey-overview',
    component: () => import('@/participants/surveys/SurveysForm.vue'),
    meta: {
      pageTitle: 'Survey Overview',
      navActiveLink: 'participant-surveys',
      breadcrumb: [
        {
          text: 'Survey',
          name: 'participant-surveys'
        },
        {
          text: 'Survey Overview',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/surveys/:form/matches/:match',
    name: 'participant-match-survey-overview',
    component: () => import('@/participants/surveys/SurveysForm.vue'),
    meta: {
      pageTitle: 'Survey Overview',
      navActiveLink: 'participant-surveys',
      breadcrumb: [
        {
          text: 'Survey',
          name: 'participant-surveys'
        },
        {
          text: 'Survey Overview',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    // @TODO old route needs to be checked and removed
    path: '/:clientSlug/:programPath/surveys/:form/questions/:questionId',
    name: 'participant-survey-edit',
    component: () => import('@/participants/surveys/SurveyEdit.vue'),
    meta: {
      pageTitle: 'Survey Edit',
      breadcrumb: [
        {
          text: 'Survey',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/surveys/:form/questions/:questionId/:token',
    name: 'participant-survey-public-edit',
    component: () => import('@/participants/surveys/SurveyEdit.vue'),
    meta: {
      pageTitle: 'Survey Edit',
      breadcrumb: [
        {
          text: 'Survey',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
      layout: 'participant',
      publicRoute: true,
    },
    props: route => ({ token: route.params.token }),
    beforeEnter: validateEmailToken
  },

  {
    path: '/:clientSlug/:programPath/resources',
    name: 'participant-resources',
    component: () => import('@/participants/resources/ResourcesList.vue'),
    meta: {
      pageTitle: 'Resources',
      breadcrumb: [
        {
          text: 'Resources',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/resources/:resource',
    name: 'participant-resource',
    component: () => import('@/participants/resources/ResourceDetail.vue'),
    meta: {
      pageTitle: 'Resources',
      breadcrumb: [
        {
          text: 'Resources',
          active: true,
        },
        {
          text: 'Resource',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },

  {
    path: '/:clientSlug/:programPath/messages',
    name: 'participant-messages',
    component: () => import('@/participants/communications/Messages.vue'),
    meta: {
      pageTitle: 'Messages',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: 'Messages',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/messages/:messageId',
    name: 'participant-messages',
    component: () => import('@/participants/communications/Messages.vue'),
    meta: {
      pageTitle: 'Messages',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: 'Messages',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/sent',
    name: 'participant-sent',
    component: () => import('@/participants/communications/Sent.vue'),
    meta: {
      pageTitle: 'Sent',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'participant-messages',
      breadcrumb: [
        {
          text: 'Sent Messages',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },

  {
    path: '/:clientSlug/:programPath/undelivered',
    name: 'participant-undelivered',
    component: () => import('@/participants/communications/Undelivered.vue'),
    meta: {
      pageTitle: 'Undelivered',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'participant-messages',
      breadcrumb: [
        {
          text: 'Undelivered Messages',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },

  {
    path: '/:clientSlug/:programPath/chat',
    name: 'participant-chat',
    component: () => import('@/views/apps/champion/dashboards/Dashboards.vue'),
    meta: {
      pageTitle: 'Chat',
      breadcrumb: [
        {
          text: 'chat',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },

  {
    path: '/:clientSlug/:programPath/tutorial',
    name: 'participant-tutorial',
    component: () => import('@/views/apps/champion/dashboards/Dashboards.vue'),
    meta: {
      pageTitle: 'Tutorial',
      breadcrumb: [
        {
          text: 'tutorial',
          active: true,
        },
      ],
    },
  },

  {
    path: '/:clientSlug/:programPath/profile',
    name: 'participant-profile',
    component: () => import('@/participants/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        }
      ],
      resource: 'ParticipantNavs'
    },
   
  },
  
  {
    path: '/:clientSlug/:programPath/training',
    name: 'participant-training-list',
    component: () => import('@/participants/training/TrainingList.vue'),
    meta: {
      pageTitle: 'Training',
      breadcrumb: [
        {
          text: 'Training',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },

  {
    path: '/:clientSlug/:programPath/setting',
    name: 'participant-setting',
    component: () => import('@/participants/Setting.vue'),
    meta: {
      pageTitle: 'Setting',
      breadcrumb: [
        {
          text: 'Setting',
          active: true,
        },
      ],
    },
  },

  {
    path: '/:clientSlug/:programPath/my-programs',
    name: 'participant-my-programs',
    component: () => import('@/participants/MyPrograms.vue'),
    meta: {
      pageTitle: 'My Programs',
      breadcrumb: [
        {
          text: 'My Programs',
          active: true,
        },
      ],
      resource: 'ParticipantNavs'
    },
  },

  {
    path: '/oauth/authorize',
    name: 'participant-oauth-authorize',
    component: () => import('@/participants/Authorize.vue'),
    meta: {
      pageTitle: 'Authorize',
      breadcrumb: [
        {
          text: 'Oauth',
          active: true,
        },
        {
          text: 'Authorize',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },

  {
    path: '/:clientSlug/:programPath/users/:userId/invite/:token',
    name: 'participant-invite',
    component: () => import('@/participants/EmailInvite.vue'),
    meta: {
      layout: 'participant',
      resource: 'Auth',
      publicRoute: true
    },
  },

  {
    path: '/:clientSlug/:programPath/my-matches',
    name: 'participant-my-matches',
    component: () => import('@/participants/MyMatches/MyMatches.vue'),
    meta: {
      pageTitle: 'My Matches',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: 'My Matches',
          active: true,
        },
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/notifications',
    name: 'participant-notifications',
    component: () => import('@/views/apps/champion/notifications/Notifications.vue'),
    meta: {
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
          active: true
        }
      ],
      resource: 'ParticipantNavs',
    }
  },
  {
    path: '/:clientSlug/:programPath/find-a-match',
    name: 'participant-find-a-match',
    component: () => import('@/participants/mentoring-on-demand/FindMatchSuggestions.vue'),
    meta: {
      pageTitle: 'Find a Match',
      breadcrumb: [
        {
          text: 'Find a Match',
          active: true
        }
      ],
      resource: 'ParticipantNavs',
    }
  },
  {
    path: '/:clientSlug/:programPath/match-requests',
    name: 'participant-match-requests',
    component: () => import('@/participants/mentoring-on-demand/MatchRequests.vue'),
    meta: {
      pageTitle: 'Match Requests',
      breadcrumb: [
        {
          text: 'Match Requests',
          active: true
        }
      ],
      resource: 'ParticipantNavs',
    }
  },
  {
    path: '/:clientSlug/:programPath/participants',
    name: 'participant-participants',
    component: () => import('@/participants/participants/ParticipantList.vue'),
    meta: {
      pageTitle: 'Participants',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: 'Participants',
          active: true,
        }
      ],
      resource: 'ParticipantNavs',
    },
  },
  {
    path: '/:clientSlug/:programPath/partner-preferencing',
    name: 'participant-partner-preferencing',
    component: () => import('@/participants/partner-preferencing/PartnerPreferencing.vue'),
    meta: {
      pageTitle: 'Partner Preferencing',
      breadcrumb: [
        {
          text: 'Partner Preferencing',
          active: true
        }
      ],
      resource: 'ParticipantNavs',
    }
  },
  {
    path: '/:clientSlug/:programPath/:groupId/group-notifications',
    name: 'participant-group-notifications',
    component: () => import('@/views/apps/champion/notifications/GroupNotifications.vue'),
    props: true,
    meta: {
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
        }
      ],
      resource: 'ParticipantNavs',
    }
  },
  {
    path: '/:clientSlug/:programPath/notifications',
    name: 'participants-notifications',
    component: () => import('@/views/apps/champion/notifications/Notifications.vue'),
    props: true,
    meta: {
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
        }
      ],
      resource: 'ParticipantNavs',
    }
  },
];
