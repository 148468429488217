import client from "./http";

class ActionableEventsService {
  constructor(axios) {
    this.axios = axios;
  }

  getActionableEvents() {
    return this.axios.get(`/actionable-events`);
  }

  getProgramActionableEvents(programId) {
    return this.axios.get(`/programs/${programId}/actionable-events`);
  }

}

const actionableEventsService = new ActionableEventsService(client);
export default actionableEventsService;
