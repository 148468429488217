export const linkTypes = Object.freeze({
  FACEBOOK: 1,
  TWITTER: 2,
  YOUTUBE: 3,
  LINKEDIN: 4,
  EMAIL: 5,
  WEBSITE: 6,
  RECRUITMENT_VIDEO: 7,
});

export const linkTypesDisplay = {
  [linkTypes.FACEBOOK]: 'Facebook',
  [linkTypes.TWITTER]: 'Twitter',
  [linkTypes.YOUTUBE]: 'Youtube',
  [linkTypes.LINKEDIN]: 'Linkedin',
  [linkTypes.EMAIL]: 'Email',
  [linkTypes.WEBSITE]: 'Website',
  [linkTypes.RECRUITMENT_VIDEO]: 'Recruitment video',
};
