export const trainingStatus = Object.freeze({
  NOT_STARTED: 0,
  STARTED: 1,
  COMPLETE: 2,
  INCOMPLETE: 3
});

export const trainingStatusDisplay = {
  [trainingStatus.NOT_STARTED]: 'Not Started',
  [trainingStatus.STARTED]: 'Started',
  [trainingStatus.INCOMPLETE]: 'Incomplete',
  [trainingStatus.COMPLETE]: 'Complete',
};

export const trainingStatusSelect = Object.keys(trainingStatusDisplay).map(key => ({ value: Number(key), text: trainingStatusDisplay[key] }));
