import client from "./http";

class PlatformMetricsService {
  
  constructor(axios) {
    this.axios = axios;
  }

  getChampionPageViews(fromDate, toDate) {
    return this.axios.get(`/platform-metrics/champion-page-views`, {params: {fromDate, toDate}});
  }

  getChampionActivity(fromDate, toDate) {
    return this.axios.get(`/platform-metrics/champion-activity`, {params: {fromDate, toDate}});
  }

  getParticipantPageViews(fromDate, toDate) {
    return this.axios.get(`/platform-metrics/participant-page-views`, {params: {fromDate, toDate}});
  }

  getParticipantActivity(fromDate, toDate) {
    return this.axios.get(`/platform-metrics/participant-activity`, {params: {fromDate, toDate}});
  }

  getProgramTypeUsage(fromDate, toDate) {
    return this.axios.get(`/platform-metrics/program-type-usage`, {params: {fromDate, toDate}});
  }

  getProgramActivity(fromDate, toDate) {
    return this.axios.get(`/platform-metrics/program-activity`, {params: {fromDate, toDate}});
  }

  getActiveUsers(fromDate, toDate) {
    return this.axios.get(`/platform-metrics/active-users`, {params: {fromDate, toDate}});
  }
  
}

const platformMetricsService = new PlatformMetricsService(client);
export default platformMetricsService;
