import client from './http';

class ProgramLogoService {

  constructor(axios) {
    this.axios = axios;
  }

  create(programId, data) {
    return this.axios.post(`/programs/${programId}/logos`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  delete(programId, logoId) {
    return this.axios.delete(`/programs/${programId}/logos/${logoId}`);
  }

  order(programId, data) {
    return this.axios.patch(`/programs/${programId}/logos`, data);
  }

  update(programId, logoId, data) {
    return this.axios.post(`/programs/${programId}/logos/${logoId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        _method: 'PATCH'
      }
    });
  }
}

const programLogoService = new ProgramLogoService(client);
export default programLogoService;
