import { 
  advancedSeachModel,
  entitys, 
  commonOperators, 
  usersRolesFilterFields, 
  applicationsFilterFields, 
  aggregate,
  aggregateTypes, 
  aggregateTypesDisplay,
  filters
} from './advancedSearch';
import { partnerPrefercingRules, partnerPreferEntitys } from './partnerPrefercingRules';
import { 
  userRoles, 
  formStatus,
  userStatus,
  operatorCommonDisplay,
  numericalStringDisplay
} from "@models";

export const partnerPrefTemplates = [
  {
    name: "List of mentees who's submitted their partner preferences", 
    entity: {id: partnerPreferEntitys.MATCH_PREFERENCES, name: 'Match Preferences'},
    filters: [
      {
        ...partnerPrefercingRules[partnerPreferEntitys.MATCH_PREFERENCES],
        type: partnerPreferEntitys.MATCH_PREFERENCES,
        aggregate: {
          ...aggregate,
          typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
          type: aggregateTypesDisplay[1].id,
          op: numericalStringDisplay[0].id,
          value: 0
        }
      },
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          },
          { 
            field: applicationsFilterFields.APPLICATION_FORM,
            op: commonOperators.OP_EQUAL_TO,
            value: undefined
          }
        ],
        type: entitys.APPLICATIONS
      },
    ],
    type: filters.FILTER_SET_ALL
  },
  {
    name: "List of mentees who haven't submitted their partner preferences", 
    entity: {id: partnerPreferEntitys.MATCH_PREFERENCES, name: 'Match Preferences'},
    filters: [
      {
        ...partnerPrefercingRules[partnerPreferEntitys.MATCH_PREFERENCES_AS_PREFERENCED_USER],
        type: partnerPreferEntitys.MATCH_PREFERENCES_AS_PREFERENCED_USER,
        aggregate: {
          ...aggregate,
          typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
          type: aggregateTypesDisplay[1].id,
          op: operatorCommonDisplay[0].id,
          value: 0
        }
      },
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          },
          { 
            field: applicationsFilterFields.APPLICATION_FORM,
            op: commonOperators.OP_EQUAL_TO,
            value: undefined
          }
        ],
        type: entitys.APPLICATIONS
      },
    ]
  },
  {
    name: "List of preferred mentors ", 
    entity: {id: partnerPreferEntitys.MATCH_PREFERENCES, name: 'Match Preferences'},
    filters: [
      {
        ...partnerPrefercingRules[partnerPreferEntitys.MATCH_PREFERENCES_AS_PREFERENCED_USER],
        type: partnerPreferEntitys.MATCH_PREFERENCES_AS_PREFERENCED_USER,
        aggregate: {
          ...aggregate,
          typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
          type: aggregateTypesDisplay[1].id,
          op: numericalStringDisplay[0].id,
          value: 0
        }
      },
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          },
          { 
            field: applicationsFilterFields.APPLICATION_FORM,
            op: commonOperators.OP_EQUAL_TO,
            value: undefined
          }
        ],
        type: entitys.APPLICATIONS
      },
    ]
  },
  {
    name: "List of mentors who were not preferred", 
    entity: {id: entitys.MATCH_PREFERENCES, name: 'Match Preferences'},
    filters: [
      {
        ...partnerPrefercingRules[partnerPreferEntitys.MATCH_PREFERENCES_AS_PREFERENCED_USER],
        type: partnerPreferEntitys.MATCH_PREFERENCES_AS_PREFERENCED_USER,
        aggregate: {
          ...aggregate,
          typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
          type: aggregateTypesDisplay[1].id,
          op: numericalStringDisplay[0].id,
          value: 0
        }
      },
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          },
          { 
            field: applicationsFilterFields.APPLICATION_FORM,
            op: commonOperators.OP_EQUAL_TO,
            value: undefined
          }
        ],
        type: entitys.APPLICATIONS
      },
    ],
  }
];
