import client from './http';

class AuthService {

  constructor(axios) {
    this.axios = axios;
  }

  forgotPassword(data) {
    return this.axios.post('/auth/forgot-password', data);
  }

  resetPassword(data) {
    return this.axios.post('/auth/reset-password', data);
  }

  getCsrfCookie() {
    // axios will set X-XSRF-TOKEN header automatically
    return this.axios({ url: '/sanctum/csrf-cookie', baseURL: process.env.VUE_APP_BE_API_URL || '/' });
  }

  login(email, password, options = {}) {
    return this.axios.post('/auth/login', { email, password, ...options });
  }

  sendTfaCode(email, password, method) {
    return this.axios.post('/auth/send-tfa-code', { email, password, method });
  }

  loginWithTfaCode(email, password, code) {
    return this.axios.post('/auth/login-with-tfa-code', { email, password, code });
  }

  logout() {
    return this.axios.post('/auth/logout');
  }

  updateUserProfile(data) {
    return this.axios.patch('/profile', data);
  }

  getUserProfile() {
    return this.axios.get('/profile');
  }

  getOauthAuthorize(params) {
    return this.axios.get('/oauth/authorize',
      { params: params },
      {
        headers: {
          Accept: 'application/json'
        },
      },
    );
  }

  changePassword(data) {
    return this.axios.patch('/profile/password', data);
  }

  loginAsUser(programId, token) {
    return this.axios.post(`/programs/${programId}/login-as-user`, {
      token: token
    });
  }

  makeloginAsUserToken(programId, userId) {
    return this.axios.get(`/programs/${programId}/make-login-token/${userId}`);
  }
}

const authService = new AuthService(client);
export default authService;
