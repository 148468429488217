export const userStatus = Object.freeze({
  INACTIVE: 0,
  ACTIVE: 1,
  SUSPENDED: 2
});

export const userStatusDisplay = {
  [userStatus.INACTIVE]: 'Inactive',
  [userStatus.ACTIVE]: 'Active',
  [userStatus.SUSPENDED]: 'Suspended'
};

export const userStatusSelect = Object.keys(userStatusDisplay).map(user => ({value: Number(user), text: userStatusDisplay[user]}));