import client from './http';

class ClientsService {

  constructor(axios) {
    this.axios = axios;
  }

  getList(search) {
    return this.axios.get('/clients', {params: {search: JSON.stringify(search)}});
  }

  create(data) {
    return this.axios.post('/clients', data);
  }

  getClientById(clientId) {
    return this.axios.get(`/clients/${clientId}`);
  }
  deleteClient(clientId) {
    return this.axios.delete(`/clients/${clientId}`);
  }
  duplicateClientProgram(clientId, data) {
    return this.axios.post(`/clients/${clientId}/programs/duplicate`, data);
  }
}

const clientsService = new ClientsService(client);
export default clientsService;
