import client from './http';

class UsersService {

  constructor(axios) {
    this.axios = axios;
  }

  getList() {
    return this.axios.get('/users');
  }
  getUser(id) {
    return this.axios.get(`/users/${id}`);
  }
  postCreateUser(user) {
    return this.axios.post('/users', user);
  }
  listAdmins(search) {
    return this.axios.get('/users/admins', { params: { search } });
  }

  listProgramAdmins(search) {
    return this.axios.get('/users/program-admins', { params: { search } });
  }

  createAdmin(user) {
    return this.axios.post('/users/admins', user);
  }

  createProgramAdmin(user) {
    return this.axios.post('/users/program-admins', user);
  }

  updateChampionOrAdmin(id, user) {
    return this.axios.patch(`/users/${id}`, user);
  }
  deleteAdminUser(id) {
    return this.axios.delete(`/users/${id}/admin`);
  }
  getListByProgram(programId, search) {
    return this.axios.get(`/programs/${programId}/users`, { params: { search } });
  }

  getChampionsByClient(clientId) {
    return this.axios.get(`/clients/${clientId}/users/champions`);
  }

  getChampionsByProgram(programId, search) {
    return this.axios.get(`/programs/${programId}/users/champions`, { params: { search } });
  }

  getChampionsAndPAByProgram(programId, search) {
    return this.axios.get(`/programs/${programId}/users/champions-and-pa`, { params: { search } });
  }
  getListParticipantsByProgram(programId, search, withParticipantCandidates = false, withChampions = false, withProgramAdmins = false) {
    return this.axios.get(`/programs/${programId}/users/participants`, { params: { 
      search, 
      with_participant_candidates: withParticipantCandidates? 1: 0, 
      with_champions: withChampions? 1: 0,
      with_program_admins: withProgramAdmins? 1: 0 
    } });
  }
  getListUserMatches(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}/matches`);
  }
  getProgramUser(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}`);
  }
  patchUpdateProgramUser(programId, userId, user) {
    return this.axios.patch(`/programs/${programId}/users/${userId}`, user);
  }

  registerParticipant(programId, data) {
    return this.axios.post(`/programs/${programId}/users/register`, data);
  }

  sendInviteToExistingParticipant(programId, data) {
    return this.axios.post(`/programs/${programId}/users/invite`, data);
  }

  registerExistingParticipant(programId, userId, token) {
    return this.axios.post(`/programs/${programId}/users/${userId}/register/${token}`);
  }

  verifyParticipantEmail(programId, userId, token) {
    return this.axios.get(`/programs/${programId}/users/${userId}/verify/${token}`);
  }
  getProgramUser(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}`);
  }
  selectParticipantRoles(programId, data) {
    return this.axios.post(`/programs/${programId}/users/roles`, data);
  }
  createParticipant(programId, data) {
    return this.axios.post(`/programs/${programId}/users/participants`, data);
  }
  // Adavnced Search
  createUserSearch(programId, data) {
    return this.axios.post(`/programs/${programId}/users/participants/searches`, data);
  }
  previewUserSearch(programId, data) {
    return this.axios.post(`/programs/${programId}/users/participants/searches/preview`, data);
  }
  readUserSearch(programId, searchId) {
    return this.axios.get(`/programs/${programId}/users/participants/searches/${searchId}`);
  }

  // Client Participants
  updateClientUser(clientId, userId, data) {
    return this.axios.patch(`/clients/${clientId}/users/${userId}`, data);
  }

  setClientParticipantStatus(clientId, userId, data) {
    return this.axios.patch(`/clients/${clientId}/users/${userId}/participant/status`, data);
  }

  setClientParticipantStatusMany(clientId, data) {
    return this.axios.patch(`/clients/${clientId}/users/participants/status`, data);
  }

  deleteClientParticipantMany(clientId, data) {
    return this.axios.delete(`/clients/${clientId}/users/participants`, { data });
  }

  // BULK updates
  setManyParticipantStatus(programId, data) {
    return this.axios.patch(`/programs/${programId}/users/participants/status`, data);
  }
  deleteManyParticipants(programId, data) {
    return this.axios.delete(`/programs/${programId}/users/participants`, { data });
  }
  setManyParticipantProgramSettings(programId, data) {
    return this.axios.patch(`/programs/${programId}/users/participants/settings`, data);
  }
  sendManyParticipantPasswordReset(programId, data) {
    return this.axios.post(`/programs/${programId}/users/participants/send-password-reset`, data);
  }
  // User Data 
  getDownloadUserData(userId) {
    return this.axios.get(`/users/${userId}/download`, { responseType: 'blob' });
  }
  // Delete SELF
  deleteSelf(data) {
    return this.axios.delete(`/profile`, { data });
  }
  // Admin Delete User
  deleteUsers(data) {
    return this.axios.delete(`/users`, { data });
  }
  updateSelf(data) {
    return this.axios.patch(`/profile`, data);
  }

  setProgramChampionStatus(programId, userId, statusId) {
    return this.axios.patch(`/programs/${programId}/users/${userId}/champion/status`, {status_id: statusId});
  }

  setClientChampionStatus(clientId, userId, statusId) {
    return this.axios.patch(`/clients/${clientId}/users/${userId}/champion/status`, {status_id: statusId});
  }

  resendChampionProgramAccess(programId, userId) {
    return this.axios.post(`/programs/${programId}/users/${userId}/champion/resend-access`);
  }

  deleteProgramChampion(programId, userId) {
    return this.axios.delete(`/programs/${programId}/users/${userId}/champion`);
  }

  deleteClientChampion(clientId, userId) {
    return this.axios.delete(`/clients/${clientId}/users/${userId}/champion`);
  }

}

const usersService = new UsersService(client);
export default usersService;
