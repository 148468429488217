/* eslint-disable camelcase */
export class SearchExprClass {
  constructor ({
    id,
    type,
    filters = [],
    created_at = undefined,
  } = {}) {
    this.id = id;
    this.type = type;
    this.filters = filters.map(f => new SearchFilterClass(f));
    this.created_at = created_at;
  }
}

export class SearchFilterClass {
  constructor ({
    id,
    type,
    expressions = [],
    aggregate = [],
    created_at = undefined,
  } = {}) {
    this.id = id;
    this.type = type;
    this.aggregate = new SearchAggregateClass(aggregate);
    this.expressions = expressions ? expressions.map(f => new ExprClass(f)) : [];
    this.created_at = created_at;
  }
}

export class SearchAggregateClass {
  constructor ({
    id,
    type,
    op,
    value,
    created_at = undefined,
  } = {}) {
    this.id = id;
    this.type = type;
    this.op = op;
    this.value = value;
    this.created_at = created_at;
  }
}
export class ExprClass {
  constructor ({
    id,
    field,
    op,
    value,
    created_at,
  } = {}) {
    this.id = id;
    this.field = field;
    this.op = op;
    this.value = value;
    this.created_at = created_at;
  }
}
  
