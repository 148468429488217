export const questionTypes = Object.freeze({
  TEXT: 1,
  MATCHING: 2,
  SCALE: 3,
  CHOICE: 4,
  STATEMENT:5
});
export const questionTypesDisplay = [
  { value: questionTypes.TEXT, text: 'Single Text Box'},
  { value: questionTypes.MATCHING, text: 'Matching Question'},
  { value: questionTypes.CHOICE, text: 'Multiple Choice'},
  { value: questionTypes.STATEMENT, text: 'Rating Scale Statement'}
];

export const questionTypesDisplaySurveys = [
  { value: questionTypes.TEXT, text: 'Single Text Box'},
  { value: questionTypes.CHOICE, text: 'Multiple Choice'},
  { value: questionTypes.STATEMENT, text: 'Rating Scale Statement'}
];
