import { userRoles } from '@/models/userRoles';
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  const user = localStorage.getItem('userData');
  return JSON.parse(user);
};

export const setUserData = userData => {
  localStorage.setItem('userData', JSON.stringify(userData));
};

export const getUserData = () => {
  try {
    const userData = localStorage.getItem('userData');
    if (userData) {
      return JSON.parse(userData);
    }
  } catch {
    return null;
  }
};

export const removeUserData = () => {
  localStorage.removeItem('userData');
};

export const setProgramData = program => {
  localStorage.setItem('programData', JSON.stringify(program));
};

export const getProgramData = () => {
  try {
    const programData = localStorage.getItem('programData');
    if (programData) {
      return JSON.parse(programData);
    }
  } catch {
    return null;
  }
};

export const getProgramList = () => {
  try {
    const programList = localStorage.getItem('programList');
    if (programList) {
      return JSON.parse(programList);
    }
  } catch {
    return null;
  }
};

export const removeProgramData = () => {
  localStorage.removeItem('programData');
};

export const clearLocalStorage = () => {
  removeUserData();
  removeProgramData();
  localStorage.clear();
};
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === userRoles.ADMIN) return { name: 'admin-home' };
  if (userRole === userRoles.CHAMPION || userRole === userRoles.PROGRAM_ADMIN) return { name: 'champion-home' };
  if (userRole === userRoles.MENTOR || userRole === userRoles.MENTEE ) return { name: 'participant-home' };
  if(userRole === userRoles.PARTICIPANT_CANDIDATE) {
    return { name : 'participant-select-role' };
  }
  if (userRole === 'client') return { name: 'access-control' };
  return { name: 'auth-login' };
};

export const getAfterLogoutRoute = userRole => {
  if (userRole === userRoles.MENTOR || userRole === userRoles.MENTEE || userRole === userRoles.PARTICIPANT_CANDIDATE) return { name: 'participant-login' };
  return { name: 'auth-login' };
};
