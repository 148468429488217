
/* eslint-disable camelcase */
export class TopicClass {
  constructor ({
    id,
    name,
    type_id,
    subject,
    body,
    recipients = [],
    messages = [],
    channel_id,
    program_id,
    is_schedulable = false,
    is_enabled = false,
    primary_topic_id,
    created_by_id,
    created_at,
  } = {}) {
    this.id = id;
    this.name = name;
    this.type_id = type_id;
    this.subject = subject;
    this.body = body;
    this.recipients = recipients.map(r => new TopicRecipient(r));
    this.messages = messages.map(m => new TopicMessage(m));
    this.channel_id = channel_id;
    this.program_id = program_id;
    this.is_schedulable = is_schedulable;
    this.is_enabled = is_enabled;
    this.primary_topic_id = primary_topic_id;
    this.created_by_id = created_by_id;
    this.created_at = created_at;
  }
}

export class TopicMessage {
  constructor ({
    id,
    topic_id,
    channel_id,
    sender_id,
    subject,
    body,
    status_id,
    sent_at,
    message_template_id,
    parent_id,
    created_at,
    updated_at
  } = {}) {
    this.id = id;
    this.topic_id = topic_id;
    this.channel_id = channel_id;
    this.sender_id = sender_id;
    this.subject = subject;
    this.body = body;
    this.status_id = status_id;
    this.sent_at = sent_at;
    this.message_template_id = message_template_id;
    this.parent_id = parent_id;
    this.updated_at = updated_at;
    this.created_at = created_at;
  }
}

export class TopicRecipient {
  constructor ({
    id,
    recipient_id,
    recipient_type,
    raw_email_address,
    created_at,
    updated_at
  } = {}) {
    this.id = id;
    this.recipient_id = recipient_id;
    this.recipient_type = recipient_type;
    this.raw_email_address = raw_email_address;
    this.updated_at = updated_at;
    this.created_at = created_at;
  }
}