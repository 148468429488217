import axios from 'axios';
import router from '../router';
import ability from '@/libs/acl/ability';
import { initialAbility } from '@/libs/acl/config';
import store  from '@/store';
import { BE_API_URL } from '@/constants/app';
import { clearLocalStorage } from "@/auth/utils";
import Vue from 'vue';
export const EventBus = new Vue();

const axiosWithCredentials = process.env.VUE_APP_AXIOS_WITH_CREDENTIALS;
const baseUrl = BE_API_URL;
const client = axios.create();
// Config here for local development environments
// axiosWithCredentials allows the XSRF-TOKEN to be set if BE is on a seperate domain
if(axiosWithCredentials) {
  client.defaults.withCredentials = true;
}

if(baseUrl) {
  client.defaults.baseURL = baseUrl;

}

// Add a request interceptor
client.interceptors.request.use(
  async config => {
    // Check if the request method is 'POST', 'PATCH', or 'DELETE'
    if (['post', 'patch', 'delete', 'put'].includes(config.method.toLowerCase())) {
      // Make a call to the '/sanctum/csrf-cookie' API endpoint
      await client({ url: '/sanctum/csrf-cookie', baseURL: process.env.VUE_APP_BE_API_URL || '/' });
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//add a response interceptor for session timeouts
client.interceptors.response.use(
  response => {
    if( response?.config?.url !== '/sanctum/csrf-cookie' ) {
      EventBus.$emit('api-call', response);
    }
    return response;
  },
  async err => {
    if (err && err.response && err.response.status === 401) {
      clearLocalStorage();
      ability.update(initialAbility);
      // check if at participant portal.
      // clear the cookies and add check on login route for participant to redirect to home
      if(store.getters['app/isParticipantPortal']) {
        logoutUserOut(); 
      } else {
        router.push({
          name: 'auth-login',
          query: {timeout: 1, next: router.currentRoute.path}
        });
      }
    }
    if (err && err.response && err.response.status === 403) {
      console.log(err.response.message);
    }
    return Promise.reject(err);
  }
);

function logoutUserOut() {
  try {
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    const { client, path } = store.getters['app/currentProgram'];
    if (router.currentRoute.name === 'participant-login' && router.currentRoute.query.token !== undefined) {
      return;
    } else {
      router.push({
        name: 'participant-login',
        params: { clientSlug: client.path, programPath: path },
        query: {timeout: 1}
      });
    }
  } catch (e) {
    console.log(e);
    return;
  }   
}
export default client;
