import client from './http';

class TranslationService {

    constructor(axios) {
        this.axios = axios;
    }

    getTranslations() {
        return this.axios.get('/translations');
    }
}

const translationService = new TranslationService(client);
export default translationService;
