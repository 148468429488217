export const actions = Object.freeze({
  SHOW: 1,
  HIDE: 2,
  SENDMAIL: 3
});

export const actionsDisplay = [
  { id: actions.SHOW, name: 'Show'},
  { id: actions.HIDE, name: 'Hide'},
  { id: actions.SENDMAIL, name: 'Send email'},
];
