import { 
  aggregate,
  aggregateTypes, 
  aggregateTypesDisplay, 
  operatorCommonDisplay,
  commonOperators,
  componentTypes,
  numericalStringDisplay,
  filters
} from "./advancedSearch";

export const partnerPreferEntitys = Object.freeze({
  MATCH_PREFERENCES: 'match_preferences',
  MATCH_PREFERENCES_AS_PREFERENCED_USER: 'match_preferences_as_preferenced_user',
});

// Partner Pref Pools
// Matched Preferences as Preferenced User
export const matchedPreferencedFields = Object.freeze({
  POOLS_ID: `pools.id`,
});

export const matchedPreferencedFieldsDisplay = [
  { id: matchedPreferencedFields.POOLS_ID, name: 'Matching Pool'},
];
export const entitysPartPreferDisplay =  [
  { id: partnerPreferEntitys.MATCH_PREFERENCES, name: 'Matching Preferences'},
  { id: partnerPreferEntitys.MATCH_PREFERENCES_AS_PREFERENCED_USER, name: 'Matching Preferences as a Preferrenced User'},
];

export const partnerPrefercingRules = {
  [partnerPreferEntitys.MATCH_PREFERENCES]: {
    field:  [],
    op: {},
    component: {},
    values: {},
    defaultValues: {},
    service: {},
    aggregate: {
      ...aggregate,
      typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
      type: aggregateTypesDisplay[1].id,
      op: operatorCommonDisplay[1].id,
      value: 0
    },
    type: filters.FILTER_SET_ALL
  },
  [partnerPreferEntitys.MATCH_PREFERENCES_AS_PREFERENCED_USER]: {
    field:  matchedPreferencedFieldsDisplay,
    aggregate: {
      ...aggregate,
      typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
      type: aggregateTypesDisplay[1].id,
      op: numericalStringDisplay[0].id,
      value: 0
    },
    op: {
      [matchedPreferencedFields.POOLS_ID]: [{ id: commonOperators.OP_EQUAL_TO, name: 'is equal to'}]
    },
    component: {
      [matchedPreferencedFields.POOLS_ID]: componentTypes.SERVICE,
    },
    values: {
      [matchedPreferencedFields.POOLS_ID]: [],
         
    },
    defaultValues: {
      [matchedPreferencedFields.POOLS_ID]: undefined,
    },
    service: {
      [matchedPreferencedFields.POOLS_ID]: { 
        module:`matchesService`,
        endpoint: 'getMatchPools',
        placeholder: 'Select Matching Pool',
        params: (program, rule) => [program.id, {}],
        optionsFilterFn: data => data?.items.map(a => ({value: a.id, text: a.title} ))
      }
    }
  }
};