
import client from './http';

class ModService {

  constructor(axios) {
    this.axios = axios;
  }
  getSuggestionList(programId, search) {
    return this.axios.get(`/programs/${programId}/suggestions`, {params: {search}});
  }

  getSuggestionDetail(programId, userId) {
    return this.axios.get(`/programs/${programId}/suggestions/${userId}`);
  }
  
  addToShortlist(programId, user){
    return this.axios.post(`/programs/${programId}/shortlisted-users/`, user);
  }

  removeFromShortlist(programId, userId){
    return this.axios.delete(`/programs/${programId}/shortlisted-users/${userId}`);
  }

  listShortlistedUsers(programId, roleId, search) {
    return this.axios.get(`/programs/${programId}/shortlisted-users/role/${roleId}`, {params: {search}});
  }
  getShortlistedUserDetail(programId, userId) {
    return this.axios.get(`/programs/${programId}/shortlisted-users/${userId}`);
  }
  // match requests
  createMatchRequest(programId, matchRequest) {
    return this.axios.post(`/programs/${programId}/match-requests/`, matchRequest);
  }
  listMatchRequests(programId, roleId, search) {
    return this.axios.get(`/programs/${programId}/match-requests/role/${roleId}`, {params: {search}});
  }
  getMatchRequestUserDetail(programId, matchRequestId) {
    return this.axios.get(`/programs/${programId}/match-requests/${matchRequestId}`);
  }
  updateMatchRequest(programId, matchRequestId, match) {
    return this.axios.patch(`/programs/${programId}/match-requests/${matchRequestId}`, match);
  }
}

const modService = new ModService(client);
export default modService;

