export const durationUnits = Object.freeze({
  DAYS: 1,
  WEEKS: 2,
  MONTHS: 3
});

export const durationUnitsDisplay = [
  { id: durationUnits.DAYS, name: 'Days'},
  { id: durationUnits.WEEKS, name: 'Weeks'},
  { id: durationUnits.MONTHS, name: 'Months'}
];
