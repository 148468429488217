import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import VueApexCharts from 'vue-apexcharts';
import i18n from '@/libs/i18n';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import router from './router';
import store from './store';
import App from './App.vue';
// Vue Toast
import Toast from "vue-toastification";
import AomTelInput from '@/plugins/aom-tel-input';
// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/acl';
import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/tour';


// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Apex Charts
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.use(AomTelInput);
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@/assets/fonts/feather/iconfont.css'); // For form-wizard

// import styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;
Vue.config.unwrapInjectedRef = true;

Pusher.logToConsole = true;


// Add heap.io analytics 
if(process.env.VUE_APP_HEAP_API_KEY){
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load(process.env.VUE_APP_HEAP_API_KEY);
}


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
