import translationService  from '@services/translationService.js';
import _isEmpty from 'lodash/isEmpty';
const translationsCachedTimeInMins = 60;

const state = () => ({
    isPendingTranslations: false,
    isSuccessTranslations: false,
    isErrorTranslations: undefined,
    translations: {},
    lastFetched: undefined
});

const mutations = {
    getTranslationsBegin (state) {
        state.isPendingTranslations = true;
    },
    getTranslationsSuccess (state, { data }) {
        state.isSuccessTranslations = true;
        state.translations = data;
        state.lastFetched = new Date().toISOString(true);
    },
    getTranslationsError (state, { error }) {
        state.isErrorTranslations = error;
    },
    getTranslationsFinally (state) {
        state.isPendingTranslations = false;
    }
};

const actions = {
    async getTranslations ({ commit }, params) {
        const lastFetchedAsDate = state.lastFetched ? state.lastFetched : undefined;
        const isValidDate = lastFetchedAsDate && lastFetchedAsDate.isValid();
        const isEmptyTranslations = _isEmpty(state.translations);

        if (isValidDate && !isEmptyTranslations) {
            const durationSinceLastFetch = parseInt(Math.abs(new Date(state.lastFetched) - new Date()) / (1000 * 60) % 60);
            if (durationSinceLastFetch < translationsCachedTimeInMins) {
                return Promise.resolve();
            }
        }

        commit('getTranslationsBegin');
        try {
            const translations = await translationService.getTranslations(params);
            const { data } = translations;
            commit('getTranslationsSuccess', { data });
        } catch (error) {
            commit('getTranslationsError', { error });
            throw error;
        } finally {
            commit('getTranslationsFinally');
        }
    }
};

const getters = { 
    en: state => Object.assign({}, ...state.translations.map(s => ({[s.en]: s.en}) )),
    es: state => Object.assign({}, ...state.translations.map(s => {
        if(s.code === 'es') {
            return ({[s.en]: s.locale_text});
        } 
    })),
    fr: state => Object.assign({}, ...state.translations.map(s => {
        if(s.code === 'fr') {
            return ({[s.en]: s.locale_text});
        } 
    })),
    de: state => Object.assign({}, ...state.translations.map(s => {
        if(s.code === 'de') {
            return ({[s.en]: s.locale_text});
        } 
    })),
    all: (state, getters) => ({
        en: getters.en,
        es: getters.es,
        fr: getters.fr,
        de: getters.de
    })
};

export default {
    namespaced: true,           
    mutations,
    actions,
    state,
    getters
};
