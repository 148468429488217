import client from './http';

class ResourcesService {
  constructor(axios) {
    this.axios = axios;
  }

  // Library
  getResourceCategoriesList(search) {
    return this.axios.get('/library/resource-categories', { params: { search } });
  }

  getResourceCategory(resourceCategoryId) {
    return this.axios.get(`/library/resource-categories/${resourceCategoryId}`);
  }
  getProgramResourceCategory(programId, resourceCategoryId) {
    return this.axios.get(`/programs/${programId}/resource-categories/${resourceCategoryId}`);
  }

  createResourceCategory(data) {
    return this.axios.post('/library/resource-categories', data);
  }
  createProgramResourceCategory(programId, data) {
    return this.axios.post(`/programs/${programId}/resource-categories`, data);
  }

  updateResourceCategory(resourceCategoryId, data) {
    return this.axios.patch(`/library/resource-categories/${resourceCategoryId}`, data);
  }
  updateProgramResourceCategory(programId, resourceCategoryId, data) {
    return this.axios.patch(`/programs/${programId}/resource-categories/${resourceCategoryId}`, data);
  }

  deleteResourceCategory(resourceCategoryId) {
    return this.axios.delete(`/library/resource-categories/${resourceCategoryId}`);
  }
  deleteProgramResourceCategory(programId, resourceCategoryId) {
    return this.axios.delete(`/programs/${programId}/resource-categories/${resourceCategoryId}`);
  }

  getLibraryResourcesList(search) {
    return this.axios.get('/library/resources', { params: { search } });
  }

  createLibraryResource(data) {
    return this.axios.post('/library/resources', data);
  }

  getLibraryResourceById(resourceId) {
    return this.axios.get(`/library/resources/${resourceId}`);
  }

  updateLibraryResource(resourceId, data) {
    return this.axios.post(`/library/resources/${resourceId}`, data);
  }

  deleteLibraryResource(resourceId) {
    return this.axios.delete(`/library/resources/${resourceId}`);
  }

  downloadLibraryResource(resourceId, translationId) {
    return this.axios.get(`/library/resources/${resourceId}/translations/${translationId}/download`);
  }

  // Program resource categories
  getProgramResourceCategoriesList(programId, search) {
    return this.axios.get(`/programs/${programId}/resource-categories`, { params: { search } });
  }

  getProgramResourceCategory(programId, resourceCategoryId) {
    return this.axios.get(`/programs/${programId}/resource-categories/${resourceCategoryId}`);
  }

  createProgramResourceCategory(programId, data) {
    return this.axios.post(`/programs/${programId}/resource-categories`, data);
  }

  updateProgramResourceCategory(programId, resourceCategoryId, data) {
    return this.axios.patch(`/programs/${programId}/resource-categories/${resourceCategoryId}`, data);
  }

  deleteProgramResourceCategory(programId, resourceCategoryId) {
    return this.axios.delete(`/programs/${programId}/resource-categories/${resourceCategoryId}`);
  }

  // Program resources
  getProgramResources(programId, search) {
    return this.axios.get(`/programs/${programId}/resources/`, { params: { search } });
  }

  createProgramResource(programId, data) {
    return this.axios.post(`/programs/${programId}/resources/`, data);
  }

  getPublicResourceByToken(resourceId, token) {
    return this.axios.get(`/resources/${resourceId}/tokens/${token}`);
  }
  getPublicResourceById(resourceId) {
    return this.axios.get(`/resources/${resourceId}/public`);
  }

  getProgramResourceById(programId, resourceId) {
    return this.axios.get(`/programs/${programId}/resources/${resourceId}`);
  }

  updateProgramResource(programId, resourceId, data) {
    return this.axios.post(`/programs/${programId}/resources/${resourceId}`, data);
  }

  deleteProgramResource(programId, resourceId) {
    return this.axios.delete(`/programs/${programId}/resources/${resourceId}`);
  }

  addResourceToProgram(programId, data) {
    return this.axios.patch(`/programs/${programId}/resources/`, data);
  }

  downloadProgramResource(programId, resourceId, translationId) {
    return this.axios.get(`/programs/${programId}/resources/${resourceId}/translations/${translationId}/download`);
  }

  getProgramResourceLogs(programId, resourceId, search) {
    return this.axios.get(`/programs/${programId}/resources/${resourceId}/logs`, { params: { search } });
  }
}

const resourcesService = new ResourcesService(client);
export default resourcesService;
