import Vue from 'vue';
import Log from './log';
import Environments  from '@models/eEnvironments';

export default function initLog(environment, user) {
  if (!environment) {
    throw new Error('Missing parameters to init log');
  }
  const logInst = new Log(environment, user);
  Vue.prototype.$log = logInst; 

  
  // Handle vue component errors
  Vue.config.errorHandler = (error, vm, info) => {
    console.error(error, vm, info);
    logInst.error({
      error, info, user,
    });
  };
  Vue.config.warnHandler = (msg, vm, info) => {
    console.error('Vue [warn]:', info, msg, vm);
    logInst.warn({
      msg, info, user,
    });
  };

  // Handle global Js errors or jQuery files
  window.onerror = (message, source, lineno, colno, error) => {
    console.log(error);
    logInst.error({
      message, source, lineno, colno, error, user,
    });
  };
}
