import programsService from "@/services/programsService";

const initialState = () => ({
  currentProgram: null,
});

const mutations = {
  RESET_STATE (state) {
    Object.assign(state, initialState());
  },

  SET_CURRENT_PROGRAM (state, value) {
    state.currentProgram = value;
  }
};

const actions = {
  async FETCH_CURRENT_PROGRAM({ state, commit, rootGetters }, id) {
    try {
      const result = await programsService.getProgramByProgramId(id);
      if (result && result.data) {
        commit('SET_CURRENT_PROGRAM', result.data);
      }
    } catch (e) {
      console.log(e);
    }
  }
};

const getters = {
  currentProgram: (state, getters, rootState, rootGetters) => state.currentProgram
};

export default {
  namespaced: true,
  mutations,
  actions,
  state: initialState(),
  getters,
};
