
/* eslint-disable camelcase */
export class MatchingPoolClass {
  constructor ({
    id,
    title = '',
    pool_roles = [],
    groups = [],
    pool_users = [],
    unmatched_matches_count = 0,
    locked_matches_count =  0,
    pending_matches_count = 0,
    program_id = undefined,
    client_id = undefined,
    created_at = undefined,
  } = {}) {
    this.id = id;
    this.title = title;
    this.pool_roles = pool_roles;
    this.groups = groups;
    this.pool_users = pool_users;
    this.unmatched_matches_count = unmatched_matches_count;
    this.locked_matches_count = locked_matches_count;
    this.pending_matches_count = pending_matches_count;
    this.program_id = program_id;
    this.client_id = client_id;
    this.created_at = created_at;
  }
}
