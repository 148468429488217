export const surveyTypes = Object.freeze({
  PARTICIPANT: 1,
  MATCH_BASE: 2,
});

export const surveyTypesDisplay = {
  [surveyTypes.PARTICIPANT]: 'Participant Survey',
  [surveyTypes.MATCH_BASE]: 'Match-based Survey'
};

export const surveyTypesSelect = [
  { id: surveyTypes.PARTICIPANT, name: 'Participant Survey' },
  { id: surveyTypes.MATCH_BASE, name: 'Match-based Survey' },
];