
<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      v-model="showModal"
      title="Session Timeout"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      :ok-disabled="isLoading"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
      @cancel="onCancel"
    >
      <b-form>
        <p>You have been inactive for some time. Please enter your password to continue.</p>
        <input
          type="hidden"
          name="email"
          :value="userEmail"
        >
        <validation-provider
          #default="{ errors }"
          ref="password"
          name="Password"
          vid="password"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="
              errors.length > 0
                ? 'is-invalid'
                : null
            "
          >
            <b-form-input
              id="login-password"
              v-model="password"
              :state="
                errors.length > 0 ? false : null
              "
              class="form-control-merge"
              :type="passwordFieldType"
              name="login-password"
              placeholder="Password"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="
                  togglePasswordVisibility
                "
              />
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback class="text-danger">
            {{
              errors[0]
            }}
          </b-form-invalid-feedback>
        </validation-provider>
        <p 
          v-if="isSamlSsoEnabled && isParticipantPortal" 
          class="mt-1"
        >
          Alternatively you can re-authenticate using
          <b-link
            :href="samlSsoHref"
          >
            Single Sign On (SSO)
          </b-link>.
        </p>
      </b-form>
    </b-modal>
  </validation-observer>
</template>
<script>
import _debounce from "lodash/debounce";
const sessionMs = (process.env.VUE_APP_SESSION_LIFETIME_MIN || 15) * 60 * 1000;
import { authService } from '@services';
import store  from '@/store';
import { clearLocalStorage } from "@/auth/utils";
import { EventBus } from '@services/http.js';
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import {
  BInputGroup,
  BFormInput,
  BForm,
  BModal,
  BFormInvalidFeedback,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";
import {
  makeErrorToast,
} from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from 'vuex';

export default {
  components: {
    BModal,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BLink,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sessionTimer: null,
      showModal: false,
      isLoading: false,
      password: '',
    };
  },
  computed: {
    ...mapGetters('app', ['isParticipantPortal']),
    passwordToggleIcon() {
      return this.passwordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    userEmail() {
      return store.getters['profile/loggedUser']?.email || '';
    },
    samlSsoHref() {
      const program = this.$store.state.app.currentProgram;
      return program ? `/saml/sso/${program.id}` : '/saml/sso';
    },
    isSamlSsoEnabled() {
      const program = this.$store.state.app.currentProgram;
      return program !== null && program.program_saml.is_enabled;
    },
  },
  created() {
    this.setSessionTimer();
    this.$root.$on('text-editor', _debounce(this.handleKeyDownEvent, 3000));
    EventBus.$on('api-call', _debounce(this.handleKeyDownEvent, 3000));
    window.addEventListener('keydown', _debounce(this.handleKeyDownEvent, 3000));
    window.addEventListener('storage', _debounce(this.handleStorageEvent, 1000));

  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDownEvent);
    window.removeEventListener('storage', this.handleStorageEvent);
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      try {
        const response = await authService.login(
          this.userEmail,
          this.password
        );
        this.isLoading = false;
        this.showModal = false;
        if (response?.status && response.status === 202) {
          //needs TFA
          this.onCancel();
        }
      } catch (e) {
        console.log(e);
        if(e?.response) {
          const { status, data } = e.response;
          if (status === 404 || status === 429 || status === 409) {
            this.$refs.observer.setErrors({
              password: [data.message]
            });
            return;
          }
        }
        this.$toast(makeErrorToast('Login failed.'));
        this.showModal = false;
        this.onCancel();
      } finally {
        this.isLoading = false;
      }
    },
    onHidden() {
      this.password = '';
    },
    onCancel() {
      clearLocalStorage();
      if(store.getters['app/isParticipantPortal']) {
        this.logoutUserOut(); 
      } else {
        this.$router.push({
          name: 'auth-login',
          query: {timeout: 1, next: this.$route.path}
        });
      }
    },
    async handleKeyDownEvent() {
      if (this.showModal) {
        return;
      }
      await this.keepApiSessionAlive();
      this.deleteSessionTimer();
      if (store.getters['profile/isLoggedIn']) {
        this.setSessionTimer();
      }
    }, 
    async handleStorageEvent(event) {
      if (event.key === 'last_activity') {
        await this.keepApiSessionAlive();
        this.deleteSessionTimer();
        if (store.getters['profile/isLoggedIn']) {
          this.setSessionTimer();
        }
      }
    },
    setSessionTimer() {
      if (this.sessionTimer !== null) {
        throw new Error('Session timer already running');
      }
      // This will trigger an update across tabs
      localStorage.setItem('last_activity', new Date().getTime().toString());
      this.showModal = false;
      this.sessionTimer = setTimeout(async () => {
        this.deleteSessionTimer();
        this.showModal = true;

        try {
          await authService.getCsrfCookie();
          await authService.logout('/auth/logout');
        } catch (e) {
          const { status } = e.response;
          if (status === 401 && this.showModal === true)
          {  
            clearLocalStorage();
            this.showModal = false;
          }
        }
      }, sessionMs);
    },
    deleteSessionTimer() {
      if (this.sessionTimer !== null) {
        clearTimeout(this.sessionTimer);
        this.sessionTimer = null;
      }
    },
    logoutUserOut() {
      try {
        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        const { client } = store.getters['app/currentProgram'];
        if (this.$route.name === 'participant-login' && this.$route.query.token !== undefined) {
          return;
        } else {
          this.$router.push({
            name: 'participant-login',
            params: { clientSlug: client.path, programPath: this.$route.params.programPath },
            query: {timeout: 1, next: this.$route.path}
          });
        }
      } catch (e) {
        console.log(e);
        return;
      }   
    },
    async keepApiSessionAlive() {
      try {
        await authService.getCsrfCookie();
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
</script>