export const integrations = Object.freeze({
  TEAMS: 1,
  SKYPE: 2,
  ZOOM: 3,
});

export const integrationsDisplay = {
  [integrations.TEAMS]: 'Teams',
  [integrations.SKYPE]: 'Skype',
  [integrations.ZOOM]: 'Zoom',
};