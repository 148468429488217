/* eslint-disable camelcase */
import { RolesClass } from './userRolesClass';
export class QuestionClass {
  constructor ({
    id,
    type_id,
    form_id = 0,
    client_id,
    question_order = 0,
    template_id,
    parent_id,
    choice_limit = 1,
    weight = 5,
    answer_character_limit = 0,
    is_visible = false,
    is_summarisable = false,
    is_required = true,
    is_locked = false,
    is_repeatable = false,
    target_form_type,
    target_form_type_id,
    target_roles = [],
    translations = [],
    choices = [],
    statements = []
  } = {}) {
    this.id = id;
    this.type_id = type_id;
    this.form_id = form_id;
    this.client_id = client_id;
    this.question_order = question_order;
    this.template_id = template_id;
    this.parent_id = parent_id;
    this.choice_limit = choice_limit;
    this.weight = weight;
    this.answer_character_limit = answer_character_limit;
    this.is_visible = is_visible;
    this.is_locked = is_locked;
    this.is_summarisable = is_summarisable;
    this.is_required = is_required;
    this.is_repeatable = is_repeatable;
    this.target_form_type = target_form_type;
    this.target_form_type_id = target_form_type_id;
    this.target_roles = target_roles.map(p => new RolesClass(p));
    this.translations = translations.map(t => new QuestionsTranslationsClass(t));
    this.choices = choices.map(c => new QuestionChoiceClass(c));
    this.statements = statements.map(s => new QuestionStatementClass(s));
  }

}

export class FormType {
  constructor ({
    id = undefined,
    name = undefined,
  } = {}) {
    this.id = id;
    this.name = name;
  }
}

export class QuestionsTranslationsClass {
  constructor ({
    id = undefined,
    locale_id = undefined,
    question_id = undefined,
    client_id = undefined,
    title = '',
    question_text = '',
    prefilled_answer = ''
  } = {}) {
    this.id = id;
    this.locale_id = locale_id;
    this.question_id = question_id;
    this.client_id = client_id;
    this.title  = title;
    this.question_text = question_text;
    this.prefilled_answer = prefilled_answer;
  }
}

export class QuestionChoiceClass {
  constructor ({
    id = undefined,
    question_id = undefined,
    choice_order = 0,
    is_other = false,
    client_id = undefined,
    translations = [],
    matched_choices = []
  } = {}) {
    this.id = id;
    this.question_id = question_id;
    this.choice_order = choice_order;
    this.is_other = is_other;
    this.client_id = client_id;
    this.translations = translations.map(t => new QuestionChoiceTranslationClass(t));
    this.matched_choices = matched_choices.map(m => new QuestionMatchedChoicesClass(m));
  }
}

export class QuestionChoiceTranslationClass {
  constructor ({
    id = undefined,
    locale_id = undefined,
    question_choice_id = undefined,
    client_id = undefined,
    choice_text = undefined
  } = {}) {
    this.id = id;
    this.locale_id = locale_id;
    this.question_choice_id = question_choice_id;
    this.client_id = client_id;
    this.choice_text = choice_text;
  }
}

export class QuestionStatementClass {
  constructor ({
    id = undefined,
    question_id = undefined,
    choice_order = 0,
    is_other = false,
    client_id = undefined,
    translations = []
  } = {}) {
    this.id = id;
    this.question_id = question_id;
    this.choice_order = choice_order;
    this.is_other = is_other;
    this.client_id = client_id;
    this.translations = translations.map(t => new QuestionsTranslationsClass(t));
  }
}

export class QuestionMatchedChoicesClass {
  constructor ({
    choice_id = undefined,
    matched_choice_id = undefined,
    choice_order = undefined,
  } = {}) {
    this.choice_id = choice_id;
    this.matched_choice_id = matched_choice_id;
    this.choice_order = choice_order;
  }
}
