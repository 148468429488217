import {
  participantRolesDisplay,
  userRoles,
  matchStatusDisplaySelect,
  matchStatus,
  trainingStatus,
  trainingStatusSelect,
  formStatus,
  formStatusSelect,
  questionTypes,
  userStatusSelect,
} from "@models";

export const entitys = Object.freeze({
  USERS: "users",
  USER_ROLES: "user_roles",
  NOTES: "notes",
  MATCHES: "program_matches",
  TRAININGS: "trainings",
  TRAINING_ROLES: "training_roles",
  APPLICATIONS: "applications",
  APPLICATION_ANSWERS: "application_answers",
  SURVEYS: "surveys",
  SURVEY_ANSWERS: "survey_answers",
  GROUPS: "groups",
  USER_PROGRAM_SETTINGS: "user_program_settings",
});
export const entitysDisplay = [
  { id: entitys.USERS, name: "Users" },
  { id: entitys.USER_ROLES, name: "User Settings" },
  { id: entitys.NOTES, name: "Notes" },
  { id: entitys.MATCHES, name: "Matches" },
  { id: entitys.TRAININGS, name: "Training" },
  { id: entitys.APPLICATIONS, name: "Applications" },
  { id: entitys.APPLICATION_ANSWERS, name: "Application Answers" },
  { id: entitys.SURVEYS, name: "Surveys" },
  { id: entitys.SURVEY_ANSWERS, name: "Survey Answers" },
  { id: entitys.GROUPS, name: "Groups" },
];
export const filters = Object.freeze({
  FILTER_SET_ALL: "filterSetAll",
  FILTER_SET_ANY: "filterSetAny",
});

export const commonOperators = Object.freeze({
  OP_EQUAL_TO: "equalTo",
  OP_NOT_EQUAL_TO: "notEqualTo",
});
export const stringOperators = Object.freeze({
  ...commonOperators,
  OP_CONTAINS: "contains",
  OP_NOT_CONTAINS: "notContains",
  OP_BEGINS_WITH: "beginsWith",
  OP_NOT_BEGINS_WITH: "notBeginsWith",
  OP_ENDS_WITH: "endsWith",
  OP_NOT_ENDS_WITH: "notEndsWith",
});
export const stringOperatorsApplicationAns = Object.freeze({
  OP_IS_BLANK: "isBlank",
  OP_IS_NOT_BLANK: "isNotBlank",
});
export const numericalOperators = Object.freeze({
  ...commonOperators,
  OP_MORE_THAN: "moreThan",
  OP_LESS_THAN: "lessThan",
  OP_BETWEEN: "between",
});
export const dateOperators = Object.freeze({
  ...numericalOperators,
});

export const operatorCommonDisplay = [
  { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
  { id: commonOperators.OP_NOT_EQUAL_TO, name: "is not equal to" },
];

export const operatorStringDisplay = [
  { id: stringOperators.OP_CONTAINS, name: "contains" },
  { id: stringOperators.OP_NOT_CONTAINS, name: "does not contain" },
  { id: stringOperators.OP_BEGINS_WITH, name: "begins with" },
  { id: stringOperators.OP_NOT_BEGINS_WITH, name: "does not begin with" },
  { id: stringOperators.OP_ENDS_WITH, name: "ends with" },
  { id: stringOperators.OP_NOT_ENDS_WITH, name: "does not end with" },
];

export const numericalStringDisplay = [
  { id: numericalOperators.OP_MORE_THAN, name: "more than" },
  { id: numericalOperators.OP_LESS_THAN, name: "less than" },
  { id: numericalOperators.OP_BETWEEN, name: "between" },
];

export const usersFilterFields = Object.freeze({
  FIRST_NAME: `${entitys.USERS}.first_name`,
  LAST_NAME: `${entitys.USERS}.last_name`,
  EMAIL: `${entitys.USERS}.email`,
  PHONE: `${entitys.USERS}.phone`,
  TRANSFERRED_INTO_PROGRAM: "transferred_into_program",
});

export const usersFilterFieldsDisplay = [
  { id: usersFilterFields.FIRST_NAME, name: "First Name" },
  { id: usersFilterFields.LAST_NAME, name: "Last Name" },
  { id: usersFilterFields.EMAIL, name: "Email" },
  { id: usersFilterFields.PHONE, name: "Phone" },
  {
    id: usersFilterFields.TRANSFERRED_INTO_PROGRAM,
    name: "Transferred into program",
  },
];

export const usersRolesFilterFields = Object.freeze({
  ROLE: `${entitys.USER_ROLES}.role_id`,
  STATUS: `${entitys.USER_ROLES}.status_id`,
  CREATED_AT: `${entitys.USER_ROLES}.created_at`,
  MATCH_LIMIT: `${entitys.USER_ROLES}.match_limit`,
  MATCH_VACANCY: "match_vacancy",
});
export const usersRolesFilterFieldsDisplay = [
  { id: usersRolesFilterFields.ROLE, name: "Role" },
  { id: usersRolesFilterFields.STATUS, name: "Status" },
  { id: usersRolesFilterFields.CREATED_AT, name: "Date Joined" },
  { id: usersRolesFilterFields.MATCH_LIMIT, name: "Match Limit" },
  { id: usersRolesFilterFields.MATCH_VACANCY, name: "Match Vacancy" },
];
export const componentTypes = Object.freeze({
  STRING: "StringComponent",
  DATE: "DateComponent",
  SERVICE: "ServiceComponent",
  DROPDOWN: "DropDownComponent",
});
export const aggregateTypes = Object.freeze({
  ONE: "one",
  NONE: "none",
  COUNT: "count",
});
export const aggregateTypesDisplay = [
  { id: aggregateTypes.ONE, name: "Exist" },
  { id: aggregateTypes.COUNT, name: "Count" }
];

export const aggregate = {
  typeDisplay: aggregateTypesDisplay,
  operatorDisplay: [...operatorCommonDisplay, ...numericalStringDisplay],
  type: aggregateTypesDisplay[0].id,
  op: operatorCommonDisplay[0].id,
  value: undefined,
};

// Matches
export const matchesFilterFields = Object.freeze({
  MATCH_ROLE: `match_users.role_id`,
  DATE_STARTED: `${entitys.MATCHES}.started_at`,
  DATE_ENDED: `${entitys.MATCHES}.ended_at`,
  MATCH_STATUS: `${entitys.MATCHES}.status_id`,
});
export const matchesFilterFieldsDisplay = [
  { id: matchesFilterFields.MATCH_ROLE, name: "Match Role" },
  { id: matchesFilterFields.DATE_STARTED, name: "Match Date Started" },
  { id: matchesFilterFields.DATE_ENDED, name: "Match Date Ended" },
  { id: matchesFilterFields.MATCH_STATUS, name: "Match Status" },
];

// Trainings
export const trainingFilterFields = Object.freeze({
  DATE_STARTED: `program_training_users.first_access_date`,
  DATE_ENDED: `program_training_users.completed_date`,
  TRAINING_STATUS: `program_training_users.status_id`,
  TRAINING_ROLE: `training_roles.role_id`,
  TRAINING_ID: "program_training_users.training_id",
});
export const trainingFilterFieldsDisplay = [
  { id: trainingFilterFields.DATE_STARTED, name: "Training Date Started" },
  { id: trainingFilterFields.DATE_ENDED, name: "Training Date Completed" },
  { id: trainingFilterFields.TRAINING_STATUS, name: "Training Status" },
  { id: trainingFilterFields.TRAINING_ROLE, name: "Training Role" },
  { id: trainingFilterFields.TRAINING_ID, name: "Training Module" },
];

// Applications
export const applicationsFilterFields = Object.freeze({
  APPLICATION_FORM: `user_forms.form_id`,
  APPLICATION_STATUS: `user_forms.status_id`,
});
export const applicationsFilterFieldsDisplay = [
  { id: applicationsFilterFields.APPLICATION_FORM, name: "Application Form" },
  {
    id: applicationsFilterFields.APPLICATION_STATUS,
    name: "Application Status",
  },
];
// Application Answers
export const applicationAnsFilterFields = Object.freeze({
  APPLICATION_FORM: `user_forms.form_id`,
  APPLICATION_QUESTION: `answers.question_id`,
  APPLICATION_ANS: `answers.answer_text`,
  APPLICATION_CHOICE: `answers.choice_id`,
});
export const applicationAnsFilterFieldsDisplay = [
  { id: applicationAnsFilterFields.APPLICATION_FORM, name: "Application Form" },
  {
    id: applicationAnsFilterFields.APPLICATION_QUESTION,
    name: "Application Question",
  },
  {
    id: applicationAnsFilterFields.APPLICATION_ANS,
    name: "Application Answer",
  },
  {
    id: applicationAnsFilterFields.APPLICATION_CHOICE,
    name: "Application Choice",
  },
];
// Surveys
export const surveysFilterFields = Object.freeze({
  SURVEYS_FORM: `user_forms.form_id`,
  SURVEYS_STATUS: `user_forms.status_id`,
});
export const surveysFilterFieldsDisplay = [
  { id: surveysFilterFields.SURVEYS_FORM, name: "Survey Form" },
  { id: surveysFilterFields.SURVEYS_STATUS, name: "Survey Status" },
];
// Survey Answers
export const surveyAnsFilterFields = Object.freeze({
  SURVEY_FORM: `user_forms.form_id`,
  SURVEY_QUESTION: `answers.question_id`,
  SURVEY_ANS: `answers.answer_text`,
  SURVEY_CHOICE: `answers.choice_id`,
});
export const surveyAnsFilterFieldsDisplay = [
  { id: surveyAnsFilterFields.SURVEY_FORM, name: "Survey Form" },
  { id: surveyAnsFilterFields.SURVEY_QUESTION, name: "Survey Question" },
  { id: surveyAnsFilterFields.SURVEY_ANS, name: "Survey Answer" },
  { id: surveyAnsFilterFields.SURVEY_CHOICE, name: "Survey Choice" },
];
// Groups
export const groupsFilterFields = Object.freeze({
  GROUP_NAME: `groups.id`,
});
export const groupsFilterFieldsDisplay = [
  { id: groupsFilterFields.GROUP_NAME, name: "Group Name" },
];
//user program settings
export const userProgramSettingsFields = Object.freeze({
  EMAIL_NOTIFICATIONS: `user_program_settings.email_notifications`,
});
export const userProgramSettingsFieldsDisplay = [
  {
    id: userProgramSettingsFields.EMAIL_NOTIFICATIONS,
    name: "Email Notifications",
  },
];

const booleanDisplay = [
  { value: 1, text: "True" },
  { value: 0, text: "False" },
];

function findRuleExpressionValue(rule, field, currentIndex = -1) {
  let data = undefined;
  let index = 0;
  for (let expr of rule.expressions || []) {
    if (expr.field === field) {
      data = expr.value;
    }
    if (data && field != applicationAnsFilterFields.APPLICATION_QUESTION) {
      break;
    } 
    index++;
    if (currentIndex != -1 && currentIndex <= index) {
      break;
    }
  }
  return data;
}

export const advancedSeachModel = {
  [entitys.USERS]: {
    field: usersFilterFieldsDisplay,
    op: {
      [usersFilterFields.FIRST_NAME]: [
        ...operatorCommonDisplay,
        ...operatorStringDisplay,
      ],
      [usersFilterFields.LAST_NAME]: [
        ...operatorCommonDisplay,
        ...operatorStringDisplay,
      ],
      [usersFilterFields.EMAIL]: [
        ...operatorCommonDisplay,
        ...operatorStringDisplay,
      ],
      [usersFilterFields.PHONE]: [
        ...operatorCommonDisplay,
        ...operatorStringDisplay,
      ],
      [usersFilterFields.TRANSFERRED_INTO_PROGRAM]: [...operatorCommonDisplay],
    },
    component: {
      [usersFilterFields.FIRST_NAME]: componentTypes.STRING,
      [usersFilterFields.LAST_NAME]: componentTypes.STRING,
      [usersFilterFields.EMAIL]: componentTypes.STRING,
      [usersFilterFields.PHONE]: componentTypes.STRING,
      [usersFilterFields.TRANSFERRED_INTO_PROGRAM]: componentTypes.DROPDOWN,
    },
    defaultValues: {
      [usersFilterFields.FIRST_NAME]: undefined,
      [usersFilterFields.LAST_NAME]: undefined,
      [usersFilterFields.EMAIL]: undefined,
      [usersFilterFields.PHONE]: undefined,
      [usersFilterFields.TRANSFERRED_INTO_PROGRAM]: 1,
    },
    values: {
      [usersFilterFields.TRANSFERRED_INTO_PROGRAM]: booleanDisplay,
    },
    service: {},
  },
  [entitys.USER_ROLES]: {
    field: usersRolesFilterFieldsDisplay,
    op: {
      [usersRolesFilterFields.ROLE]: [...operatorCommonDisplay],
      [usersRolesFilterFields.STATUS]: [...operatorCommonDisplay],
      [usersRolesFilterFields.CREATED_AT]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
        ...numericalStringDisplay,
      ],
      [usersRolesFilterFields.MATCH_LIMIT]: [
        ...operatorCommonDisplay,
        ...numericalStringDisplay,
      ],
      [usersRolesFilterFields.MATCH_VACANCY]: [
        ...operatorCommonDisplay,
        ...numericalStringDisplay,
      ],
    },
    values: {
      [usersRolesFilterFields.ROLE]: [{ value: userRoles.CHAMPION, text: 'Champion', }, ...participantRolesDisplay, { value: userRoles.MENTOR_AND_MENTEE, text: 'Mentor and Mentee', }],
      [usersRolesFilterFields.STATUS]: userStatusSelect,
    },
    component: {
      [usersRolesFilterFields.ROLE]: componentTypes.DROPDOWN,
      [usersRolesFilterFields.STATUS]: componentTypes.DROPDOWN,
      [usersRolesFilterFields.CREATED_AT]: componentTypes.DATE,
      [usersRolesFilterFields.MATCH_LIMIT]: {
        [numericalOperators.OP_MORE_THAN]: componentTypes.STRING,
        [numericalOperators.OP_LESS_THAN]: componentTypes.STRING,
        [commonOperators.OP_EQUAL_TO]: componentTypes.STRING,
        [commonOperators.OP_NOT_EQUAL_TO]: componentTypes.STRING,
        [numericalOperators.OP_BETWEEN]: [
          componentTypes.STRING,
          componentTypes.STRING,
        ],
      },
      [usersRolesFilterFields.MATCH_VACANCY]: {
        [numericalOperators.OP_MORE_THAN]: componentTypes.STRING,
        [numericalOperators.OP_LESS_THAN]: componentTypes.STRING,
        [commonOperators.OP_EQUAL_TO]: componentTypes.STRING,
        [commonOperators.OP_NOT_EQUAL_TO]: componentTypes.STRING,
        [numericalOperators.OP_BETWEEN]: [
          componentTypes.STRING,
          componentTypes.STRING,
        ],
      },
    },
    defaultValues: {
      [usersRolesFilterFields.ROLE]: userRoles.MENTOR,
      [usersRolesFilterFields.STATUS]: 1,
      [usersRolesFilterFields.CREATED_AT]: new Date(),
      "user_roles.match_limit.between": [1, 10],
      "match_vacancy.between": [1, 10],
    },
    service: {},
  },
  [entitys.NOTES]: {
    field: [{ id: "notes.note_text", name: "Note" }],
    op: {
      ["notes.note_text"]: [...operatorCommonDisplay, ...operatorStringDisplay],
    },
    component: {
      ["notes.note_text"]: componentTypes.STRING,
    },
    defaultValues: {
      ["notes.note_text"]: undefined,
    },
    values: [],
    service: {},
  },
  [entitys.MATCHES]: {
    field: matchesFilterFieldsDisplay,
    op: {
      [matchesFilterFields.MATCH_ROLE]: [...operatorCommonDisplay],
      [matchesFilterFields.DATE_STARTED]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
        ...numericalStringDisplay,
      ],
      [matchesFilterFields.DATE_ENDED]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
        ...numericalStringDisplay,
      ],
      [matchesFilterFields.MATCH_STATUS]: [...operatorCommonDisplay],
    },
    component: {
      [matchesFilterFields.MATCH_ROLE]: componentTypes.DROPDOWN,
      [matchesFilterFields.DATE_STARTED]: componentTypes.DATE,
      [matchesFilterFields.DATE_ENDED]: componentTypes.DATE,
      [matchesFilterFields.MATCH_STATUS]: componentTypes.DROPDOWN,
    },
    values: {
      [matchesFilterFields.MATCH_ROLE]: participantRolesDisplay,
      [matchesFilterFields.MATCH_STATUS]: matchStatusDisplaySelect.slice(1),
    },
    defaultValues: {
      [matchesFilterFields.MATCH_ROLE]: userRoles.MENTOR,
      [matchesFilterFields.DATE_STARTED]: new Date(),
      [matchesFilterFields.DATE_ENDED]: new Date(),
      [matchesFilterFields.MATCH_STATUS]: matchStatus.ACTIVE,
    },
    aggregate: { ...aggregate },
    service: {},
  },
  [entitys.TRAININGS]: {
    field: trainingFilterFieldsDisplay,
    op: {
      [trainingFilterFields.DATE_STARTED]: [
        ...operatorCommonDisplay,
        ...numericalStringDisplay,
      ],
      [trainingFilterFields.DATE_ENDED]: [
        ...operatorCommonDisplay,
        ...numericalStringDisplay,
      ],
      [trainingFilterFields.TRAINING_STATUS]: [...operatorCommonDisplay],
      [trainingFilterFields.TRAINING_ROLE]: [...operatorCommonDisplay],
      [trainingFilterFields.TRAINING_ID]: [...operatorCommonDisplay],
      [trainingFilterFields.TRAINING_ROLE]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
    },
    component: {
      [trainingFilterFields.DATE_STARTED]: componentTypes.DATE,
      [trainingFilterFields.DATE_ENDED]: componentTypes.DATE,
      [trainingFilterFields.TRAINING_STATUS]: componentTypes.DROPDOWN,
      [trainingFilterFields.TRAINING_ROLE]: componentTypes.DROPDOWN,
      [trainingFilterFields.TRAINING_ID]: componentTypes.SERVICE,
    },
    values: {
      [trainingFilterFields.TRAINING_STATUS]: trainingStatusSelect,
      [trainingFilterFields.TRAINING_ROLE]: participantRolesDisplay,
    },
    defaultValues: {
      [trainingFilterFields.DATE_STARTED]: new Date(),
      [trainingFilterFields.DATE_ENDED]: new Date(),
      [trainingFilterFields.TRAINING_STATUS]: trainingStatus.COMPLETE,
      [trainingFilterFields.TRAINING_ROLE]: userRoles.MENTOR,
    },
    service: {
      [trainingFilterFields.TRAINING_ID]: {
        module: `trainingsService`,
        endpoint: "getProgramTrainings",
        placeholder: "Select Training Module",
        params: (program, rule) => [program.id],
        optionsFilterFn: data =>
          data?.items.map(i => ({ value: i.id, text: i.course_name })),
      },
    },
  },
  [entitys.APPLICATIONS]: {
    field: applicationsFilterFieldsDisplay,
    op: {
      [applicationsFilterFields.APPLICATION_FORM]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
      [applicationsFilterFields.APPLICATION_STATUS]: [...operatorCommonDisplay],
    },
    component: {
      [applicationsFilterFields.APPLICATION_FORM]: componentTypes.SERVICE,
      [applicationsFilterFields.APPLICATION_STATUS]: componentTypes.DROPDOWN,
    },
    values: {
      [applicationsFilterFields.APPLICATION_FORM]: [],
      [applicationsFilterFields.APPLICATION_STATUS]: formStatusSelect,
    },
    defaultValues: {
      [applicationsFilterFields.APPLICATION_STATUS]: formStatus.COMPLETED,
      [applicationsFilterFields.APPLICATION_FORM]: undefined,
    },
    service: {
      [applicationsFilterFields.APPLICATION_FORM]: {
        module: `applicationService`,
        endpoint: "getApplicationSets",
        placeholder: "Select Form",
        params: (program, rule) => [program.id, program.application_set?.id],
        optionsFilterFn: data =>
          data?.applications.map(a => ({
            value: a.id,
            text: a.roles[0].name,
          })),
      },
    },
  },
  [entitys.APPLICATION_ANSWERS]: {
    field: applicationAnsFilterFieldsDisplay,
    op: {
      [applicationAnsFilterFields.APPLICATION_FORM]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
      [applicationAnsFilterFields.APPLICATION_QUESTION]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
      [applicationAnsFilterFields.APPLICATION_ANS]: [...operatorStringDisplay],
      [applicationAnsFilterFields.APPLICATION_CHOICE]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
    },
    component: {
      [applicationAnsFilterFields.APPLICATION_FORM]: componentTypes.SERVICE,
      [applicationAnsFilterFields.APPLICATION_QUESTION]: componentTypes.SERVICE,
      [applicationAnsFilterFields.APPLICATION_ANS]: componentTypes.STRING,
      [applicationAnsFilterFields.APPLICATION_CHOICE]: componentTypes.SERVICE,
    },
    values: {
      [applicationAnsFilterFields.APPLICATION_FORM]: [],
      [applicationAnsFilterFields.APPLICATION_QUESTION]: [],
      [applicationAnsFilterFields.APPLICATION_ANS]: [],
      [applicationAnsFilterFields.APPLICATION_CHOICE]: [],
    },
    defaultValues: {
      [applicationAnsFilterFields.APPLICATION_FORM]: undefined,
      [applicationAnsFilterFields.APPLICATION_QUESTION]: undefined,
      [applicationAnsFilterFields.APPLICATION_ANS]: undefined,
      [applicationAnsFilterFields.APPLICATION_CHOICE]: undefined,
    },
    service: {
      [applicationAnsFilterFields.APPLICATION_FORM]: {
        module: `applicationService`,
        endpoint: "getApplicationSets",
        placeholder: "Select Form",
        params: (program, rule) => [program.id, program.application_set?.id],
        optionsFilterFn: data =>
          data?.applications.map(a => ({
            value: a.id,
            text: a.roles[0].name,
          })),
      },
      [applicationAnsFilterFields.APPLICATION_QUESTION]: {
        module: `applicationService`,
        endpoint: "getApplicationSets",
        placeholder: "Select Question",
        params: (program, rule) => [program.id, program.application_set?.id],
        optionsFilterFn: (data, rule) => {
          const formId = findRuleExpressionValue(
            rule,
            applicationAnsFilterFields.APPLICATION_FORM
          );
          const application = data?.applications.find(a => a.id === formId);
          return application?.questions
            .filter(q => q.type_id !== questionTypes.SCALE)
            .map((q) => ({
              value: q.id,
              text: `${q.question_order}. ${q.translations?.[0]?.title}`,
              type: `${q.type.name}`,
              role: `${application.roles.map(r => r.name)}`,
            }));
        },
      },
      [applicationAnsFilterFields.APPLICATION_CHOICE]: {
        module: `applicationService`,
        endpoint: "getApplicationSets",
        placeholder: "Select Question Choice",
        params: (program, rule) => [program.id, program.application_set?.id],
        optionsFilterFn: (data, rule, currentIndex) => {
          const formId = findRuleExpressionValue(
            rule,
            applicationAnsFilterFields.APPLICATION_FORM
          );
          const application = data?.applications.find(a => a.id === formId);
          const qId = findRuleExpressionValue(
            rule,
            applicationAnsFilterFields.APPLICATION_QUESTION,
            currentIndex
          );
          const question = application?.questions.find(q => q.id === qId);
          return question &&
            (question.type_id === questionTypes.CHOICE ||
              question.type_id === questionTypes.MATCHING)
            ? question?.choices.map(c => ({
                value: c.id,
                text: `${c.translations?.[0]?.choice_text}`,
                type: `${question.type.name}`,
                role: `${application.roles.map(r => r.name)}`,
              }))
            : undefined;
        },
      },
    },
  },
  [entitys.SURVEYS]: {
    field: surveysFilterFieldsDisplay,
    op: {
      [surveysFilterFields.SURVEYS_FORM]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
      [surveysFilterFields.SURVEYS_STATUS]: [...operatorCommonDisplay],
    },
    component: {
      [surveysFilterFields.SURVEYS_FORM]: componentTypes.SERVICE,
      [surveysFilterFields.SURVEYS_STATUS]: componentTypes.DROPDOWN,
    },
    values: {
      [surveysFilterFields.SURVEYS_FORM]: [],
      [surveysFilterFields.SURVEYS_STATUS]: formStatusSelect,
    },
    defaultValues: {
      [surveysFilterFields.SURVEYS_FORM]: undefined,
      [surveysFilterFields.SURVEYS_STATUS]: formStatus.COMPLETED,
    },
    service: {
      [surveysFilterFields.SURVEYS_FORM]: {
        module: `programsService`,
        endpoint: "getSurveysByProgramId",
        placeholder: "Select Form",
        params: (program, rule) => [program.id],
        optionsFilterFn: data =>
          data?.items.map(i => ({
            value: i.id,
            text: i.translations[0].title,
          })),
      },
    },
    aggregate: { ...aggregate },
  },
  [entitys.SURVEY_ANSWERS]: {
    field: surveyAnsFilterFieldsDisplay,
    op: {
      [surveyAnsFilterFields.SURVEY_FORM]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
      [surveyAnsFilterFields.SURVEY_QUESTION]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
      [surveyAnsFilterFields.SURVEY_ANS]: [...operatorStringDisplay],
      [surveyAnsFilterFields.SURVEY_CHOICE]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
    },
    component: {
      [surveyAnsFilterFields.SURVEY_FORM]: componentTypes.SERVICE,
      [surveyAnsFilterFields.SURVEY_QUESTION]: componentTypes.SERVICE,
      [surveyAnsFilterFields.SURVEY_ANS]: componentTypes.STRING,
      [surveyAnsFilterFields.SURVEY_CHOICE]: componentTypes.SERVICE,
    },
    values: {
      [surveyAnsFilterFields.SURVEY_FORM]: [],
      [surveyAnsFilterFields.SURVEY_QUESTION]: [],
      [surveyAnsFilterFields.SURVEY_ANS]: [],
      [surveyAnsFilterFields.SURVEY_CHOICE]: [],
    },
    defaultValues: {
      [surveyAnsFilterFields.SURVEY_FORM]: undefined,
      [surveyAnsFilterFields.SURVEY_QUESTION]: undefined,
      [surveyAnsFilterFields.SURVEY_ANS]: undefined,
      [surveyAnsFilterFields.SURVEY_CHOICE]: undefined,
    },
    service: {
      [surveyAnsFilterFields.SURVEY_FORM]: {
        module: `programsService`,
        endpoint: "getSurveysByProgramId",
        placeholder: "Select Form",
        params: (program, rule) => [program.id],
        optionsFilterFn: (data, rule) =>
          data?.items.map(i => ({
            value: i.id,
            text: i.translations[0].title,
          })),
      },
      [surveyAnsFilterFields.SURVEY_QUESTION]: {
        module: `formsService`,
        endpoint: "getProgramSurvey",
        placeholder: "Select A Survey Question",
        params: (program, rule) => {
          const surveyId = findRuleExpressionValue(
            rule,
            surveyAnsFilterFields.SURVEY_FORM
          );
          return [program.id, surveyId || 0];
        },
        optionsFilterFn: (data, rule) =>
          data?.questions
            .filter(q => q.type_id !== questionTypes.SCALE)
            .map(q => ({
              value: q.id,
              text: `${q.translations?.[0]?.question_text || q.translations?.[0]?.title}`,
              type: `${q.type.name}`,
            })),
      },
      [surveyAnsFilterFields.SURVEY_CHOICE]: {
        module: `formsService`,
        endpoint: "getProgramSurvey",
        placeholder: "Select Survey Question Choice",
        params: (program, rule) => {
          const surveyId = findRuleExpressionValue(
            rule,
            surveyAnsFilterFields.SURVEY_FORM
          );
          return [program.id, surveyId || 0];
        },
        optionsFilterFn: (data, rule) => {
          const questionId = findRuleExpressionValue(
            rule,
            surveyAnsFilterFields.SURVEY_QUESTION
          );
          const question = data?.questions.find(q => q.id === questionId);
          return question.choices.map(choice => ({
            value: choice.id,
            text: `${choice.translations?.[0]?.choice_text}`,
            type: `${question.type.name}`,
          }));
        },
      },
    },
  },
  [entitys.GROUPS]: {
    field: groupsFilterFieldsDisplay,
    op: {
      [groupsFilterFields.GROUP_NAME]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
    },
    component: {
      [groupsFilterFields.GROUP_NAME]: componentTypes.SERVICE,
    },
    values: {
      [groupsFilterFields.GROUP_NAME]: undefined,
    },
    defaultValues: {
      [groupsFilterFields.GROUP_NAME]: undefined,
    },
    service: {
      [groupsFilterFields.GROUP_NAME]: {
        module: `groupsService`,
        endpoint: "listGroups",
        placeholder: "Select A Group",
        params: (program, rule) => [program.id],
        optionsFilterFn: data =>
          data?.items.map(g => ({
            value: g.id,
            text: `${g.name}`,
            type: `${g.type.name}`,
          })),
      },
    },
  },
  [entitys.USER_PROGRAM_SETTINGS]: {
    field: userProgramSettingsFieldsDisplay,
    op: {
      [userProgramSettingsFields.EMAIL_NOTIFICATIONS]: [
        { id: commonOperators.OP_EQUAL_TO, name: "is equal to" },
      ],
    },
    component: {
      [userProgramSettingsFields.EMAIL_NOTIFICATIONS]: componentTypes.DROPDOWN,
    },
    values: {
      [userProgramSettingsFields.EMAIL_NOTIFICATIONS]: booleanDisplay,
    },
    defaultValues: {
      [userProgramSettingsFields.EMAIL_NOTIFICATIONS]: 1,
    },
    service: {},
  },
};
