
export const groupType = Object.freeze({
  CUSTOM: 1,
  FILTER: 2,
});

export const groupTypeDisplay = {
  [groupType.CUSTOM]: 'Custom',
  [groupType.FILTER]: 'Filter',
};

export const groupTypeSelect = [
  { value: groupType.CUSTOM, text: 'Custom'},
  { value: groupType.FILTER, text: 'Filter'},
];
