import Environments from '../models/eEnvironments';
import { datadogLogs } from '@datadog/browser-logs';

export default class Log {
  constructor(environment, user) {
    this.environment = environment;
    this.user = this.sanitiseUser(user);
    datadogLogs.init({
      clientToken: process.env.VUE_APP_DATADOG_LOG_TOKEN,
      site: process.env.VUE_APP_DATADOG_LOG_SITE,
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
    });
  }

  error(args) {
    let error, info, vm;
    if (args instanceof Error) {
      error = args;
    } else {
      ({ error, info, vm} = args);
    }
    datadogLogs.logger.error(error?.message || 'Error', { environment: this.environment, user: this.user, info }, error);
  }

  warn(args) {
    const { msg, info, vm } = args;
    datadogLogs.logger.warn(msg || 'Warning', { environment: this.environment, user: this.user , info });
  }

  info(args) {
    // Only available in DEV or Local
    if (this.environment === Environments.DEV || this.environment === Environments.LOCAL) {
      console.log(args);
    }
  }

  sanitiseUser(user) {
    if (typeof user === 'number') {
      return { id: user };
    }

    if (user && typeof user === 'object') {
      return {id: user.id || null };
    }

    return null;
  }
}
