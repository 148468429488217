export const reminderType = Object.freeze({
  APPLICATIONS: 1,
  SURVEYS: 2,
  TRAINING: 3,
});

export const reminderTooltips = {
  [reminderType.APPLICATIONS]: 'Sends a reminder every week for 4 weeks to remind users to complete their application',
  [reminderType.SURVEYS]: 'Sends reminder every week for 4 weeks after a survey is sent',
  [reminderType.TRAINING]: 'Sends a training reminder every week for 4 weeks to complete their training',

};