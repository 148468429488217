import client from './http';

class SurveyService {

  constructor(axios) {
    this.axios = axios;
  }

  // Users Surveys
  getUsersSurveyList(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}/surveys`);
  }
  // Users Surveys Q & A
  getUsersSurveyAnswers(programId, userId, formId, matchId) {
    if (matchId) {
      return this.axios.get(`/programs/${programId}/users/${userId}/matches/${matchId}/surveys/${formId}/answers`);    
    } else {
      return this.axios.get(`/programs/${programId}/users/${userId}/surveys/${formId}/answers`);
    }
  }
  runSurveyNow(programId, formId) {
    return this.axios.post(`/commands/programs/${programId}/surveys/run-schedule/${formId}`);
  }
 
}

const surveyService = new SurveyService(client);
export default surveyService;
