export const locales = Object.freeze({
  EN: 1,
  DE: 2,
  ES: 3,
  FR: 4,
  PT: 5
});

export const localesDisplay = {
  [locales.EN]: 'English',
  [locales.DE]: 'German',
  [locales.ES]: 'Spanish',
  [locales.FR]: 'French',
  [locales.PT]: 'Portuguese',
};

export const localeCodes = {
  [locales.EN]: 'en',
  [locales.DE]: 'de',
  [locales.ES]: 'es',
  [locales.FR]: 'fr',
  [locales.PT]: 'pt',
};