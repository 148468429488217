import programsService  from '@services/programsService.js';
import _isEmpty from 'lodash/isEmpty';
import { AOM_MAIN_DOMAIN } from '@/constants/app';
import { commsService, groupsService } from '@services';
import { programTypes } from '@/models';
import { setCookie, getCookieValue } from '@/libs/utils.js';


const programsCachedTimeInMins = 30;
const programListStorage = localStorage.getItem("programList");
let localStore = undefined;
if(programListStorage !== 'undefined') {
  localStore = JSON.parse(programListStorage);
}
const LAST_PROGRAM_ID = 'lastViewProgram';

const state = () => ({
  isPendingPrograms: false,
  isSuccessPrograms: false,
  isErrorPrograms: undefined,
  programs: localStore ? localStore : undefined,
  lastFetched: undefined,
  selectedProgramId: 0,
  forceRefreshParticipantMatches: false,
  program: undefined,
  messagesCountUnread: 0,
  programUserGroups: []
});

const mutations = {
  RESET_PROGRAM_STATE (state) {
    state.selectedProgramId = undefined;
    state.lastFetched = undefined;
    state.programs = undefined;
    localStorage.removeItem("programList");
  },
  GET_PROGRAM_BEGIN (state) {
    state.isPendingPrograms = true;
  },
  GET_PROGRAM_SUCCESS (state, { data }) {
    state.isSuccessPrograms = true;
    state.programs = data.items;
    
    if(data?.items && data?.items.length) {
      const userLastProgramId = getCookieValue(LAST_PROGRAM_ID);
      if( userLastProgramId !== null && data?.items.filter(p => Number(p.id) === Number(userLastProgramId).length > 0)) {
        state.selectedProgramId = userLastProgramId;
      } else {
        state.selectedProgramId = data.items[data.items.length - 1]?.id;
      }
    }
    state.lastFetched = new Date().toISOString(true);
    localStorage.setItem("programList", JSON.stringify(state.programs));
  },
  GET_PROGRAM_BY_ID_SUCCESS(state, { data }) {
    const program = state.programs?.filter(p => Number(p.id) === Number(data.id)).shift();
    state.program = {...program, ...data};
  },
  GET_PROGRAM_ERROR (state, { error }) {
    state.isErrorPrograms = error;
  },
  GET_PROGRAM_FINALLY (state) {
    state.isPendingPrograms = false;
  },
  SET_CURRENT_PROGRAM_ID (state, programId) {
    state.selectedProgramId = programId;
  },
  UPDATE_PROGRAM( state, program) {
    const updatedProgramRoles = state.programs.map(p => p.id === program.id ? ({ ...p, program_roles: program.program_roles}) : p);
    state.programs = updatedProgramRoles;
  },
  SET_FORCE_REFRESH_PARTICIPANT_MATCHES(state, value) {
    state.forceRefreshParticipantMatches = value;
  },
  SET_PROGRAM_LOGO(state, logo) {
    const updatedProgramLogo = state.programs.map(p => logo.program_id === p.id ? ({ ...p, primary_program_logo: logo}) : p);
    state.programs = updatedProgramLogo;
    localStorage.setItem("programList", JSON.stringify(state.programs));
  },
  SET_MESSAGE_UNREAD(state, messagesCountUnread) {
    state.messagesCountUnread = messagesCountUnread;
  },
  SET_PROGRAM_USER_GROUPS(state, groups) {
    state.programUserGroups = groups;
  }
};

const actions = {
  async GET_PROGRAMS ({ commit }, params) {
    const lastFetchedAsDate = state.lastFetched ? state.lastFetched : undefined;
    const isValidDate = lastFetchedAsDate && lastFetchedAsDate.isValid();
    const isEmptyPrograms = _isEmpty(state.programs);

    if (isValidDate && !isEmptyPrograms) {
      const durationSinceLastFetch = parseInt(Math.abs(new Date(state.lastFetched) - new Date()) / (1000 * 60) % 60);
      if (durationSinceLastFetch < programsCachedTimeInMins) {
        return Promise.resolve();
      }
    }

    commit('GET_PROGRAM_BEGIN');
    try {
      const programs = await programsService.getList(params);
      const { data } = programs;
      commit('GET_PROGRAM_SUCCESS', { data });
    } catch (error) {
      commit('GET_PROGRAM_ERROR', { error });
      throw error;
    } finally {
      commit('GET_PROGRAM_FINALLY');
    }
  },
  async FETCH_PROGRAM_BY_ID({ commit, dispatch }, params) {
    try {
      const programs = await programsService.getProgramByProgramId(params);
      const { data } = programs;
      commit('GET_PROGRAM_BY_ID_SUCCESS', { data });
      commit('app/SET_PROGRAM_LANGUAGES', data.languages, { root: true });
      dispatch('FETCH_COUNT_MESSAGES_UNREAD', );
    } catch (error) {
      throw error;
    }
  },
  async FETCH_COUNT_MESSAGES_UNREAD({ commit, state }, programId) {
    try {
      if (programId) {
        const response = await commsService.getCountUnread(programId);
        commit('SET_MESSAGE_UNREAD', response.data.unread_message_count);
      }
    } catch (error) {
      throw error;
    }
  },
  async FETCH_PROGRAM_USER_GROUPS({ commit, dispatch }, programId) {
    try {
      const response = await groupsService.getList(programId);
      const { items } = response.data;
      commit("SET_PROGRAM_USER_GROUPS", items);
    } catch (error) {
      throw error;
    }
  },
};

const getters = { 
  programList: state => state.programs,
  defaultProgramId:  (state, getters, rootState, rootGetters) => state.selectedProgramId ? state.selectedProgramId : rootState.profile.programs[0],
  defaultProgram: (state, getters, rootState, rootGetters) => state.program,
  defaultProgramPortal: (state, getters, rootState, rootGetters) => {
    const defaultProgram = state.programs?.find(p => Number(p.id) === Number(getters.defaultProgramId));
    if (defaultProgram) {
      return `http://${defaultProgram.client.path}.${AOM_MAIN_DOMAIN}/${defaultProgram.path}`;
    }
    return null;
  },
  defaultProgramKeyDates: (state, getters, rootState, rootGetters) => {
    return [...rootGetters['app/appKeyDates']];
  },
  defaultProgramActionableEvents: (state, getters, rootState, rootGetters) => {
    return [...rootGetters['app/appActionableEvents']];
  },
  programById: state => id => state.programs ? state.programs.filter(p => Number(p.id) === Number(id)) : state.programs,
  forceRefreshParticipantMatches: state => state.forceRefreshParticipantMatches,
  programDefaultLangs: state => state.program && state.program.languages ? state.program.languages.map(lang => ({
    id: lang.id,
    name: lang.name,
    abbreviation: lang.abbreviation,
  })) : [],
  messagesCountUnread: state => {
    return state.messagesCountUnread;
  },
  programUserGroups: state => state?.programUserGroups.map(group => ({id: group.id, name: group.name, type: group.type.name, is_champion: group.is_champion})),
  
  isProgramMOD: state => state?.program?.type_id === programTypes.MENTORING_ON_DEMAND
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
  getters
};
