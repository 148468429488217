/* eslint-disable camelcase */
import { create } from 'lodash';
import { UserRolesClass } from './userRolesClass';

export class User {
  constructor({
    id,
    first_name = '',
    last_name = '',
    full_name = '',
    phone = '',
    email = '',
    profile_image = '',
    user_roles = [],
    is_tfa_enabled = false,
    preferred_language_id,
    user_program_settings = [],
    status_id,
  } = {}) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.full_name = full_name;
    this.phone = phone;
    this.email = email;
    this.profile_image = new Image({...profile_image});
    this.user_roles = user_roles.map(r => new UserRolesClass(r));
    this.is_tfa_enabled = is_tfa_enabled;
    this.preferred_language_id = preferred_language_id;
    this.user_program_settings = user_program_settings.map(s => new UserProgramSettings(s));
    this.status_id = status_id;

  }
}

export class Image {
  constructor({
    id = undefined,
    client_id = undefined,
    filepath = undefined,
    name = undefined,
    data_uri = '',
    url = undefined,
  } = {}) {
    this.id = id;
    this.client_id = client_id;
    this.filepath = filepath;
    this.name = name;
    this.data_uri = data_uri;
    this.url = url;
  }
}
export class UserProgramSettings {
  constructor({
    id,
    client_id,
    program_id,
    email_notifications,
    created_at,
    updated_at
  } = {}) {
    this.id = id;
    this.client_id = client_id;
    this.program_id = program_id;
    this.email_notifications = email_notifications;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}
