import client from './http';

class SearchService {

  constructor(axios) {
    this.axios = axios;
  }

  getSearchResult(params) {
    return this.axios.get('/search', {
      params: params
    });
  }
  getProgramSearchResult(programId, params) {
    return this.axios.get(`/programs/${programId}/search`, {
      params: params
    });
  }
}

const searchService = new SearchService(client);
export default searchService;
