import client from './http';

class GroupsService {

  constructor(axios) {
    this.axios = axios;
  }

  getList(programId, search) {
    return this.axios.get(`/programs/${programId}/groups`, {params: {search: search}});
  }
  
  getListTemplates(programId, search) {
    return this.axios.get(`/program-templates/${programId}/groups`, {params: {search: search}});
  }

  deleteGroup(programId, groupId) {
    return this.axios.delete(`/programs/${programId}/groups/${groupId}`);
  }

  getGroupById(programId, groupId) {
    return this.axios.get(`/programs/${programId}/groups/${groupId}`);
  }

  getListGroupUsers(programId, groupId, search) {
    return this.axios.get(`/programs/${programId}/groups/${groupId}/users`, {params: {search: search}});
  }

  createFilterGroup(programId, data) {
    return this.axios.post(`/programs/${programId}/groups/filter`, data);
  }
  updateFilterGroup(programId, groupId, data) {
    return this.axios.patch(`/programs/${programId}/groups/filter/${groupId}`, data);
  }
  createCustomGroup(programId, data) {
    return this.axios.post(`/programs/${programId}/groups/custom`, data);
  }
  listGroups(programId) {
    return this.axios.get(`/programs/${programId}/groups`);
  }
  updateCustomGroup(programId, groupId, data) {
    return this.axios.patch(`/programs/${programId}/groups/custom/${groupId}`, data);
  }
  getUserGroups(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}/groups`);
  }
  patchAddUsersGroups(programId, data) {
    return this.axios.patch(`/programs/${programId}/groups/custom/add`, data);

  }
  patchRemoveUsersGroups(programId, data) {
    return this.axios.patch(`/programs/${programId}/groups/custom/remove`, data);
  }
}

const groupsService = new GroupsService(client);
export default groupsService;
