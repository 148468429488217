import client from './http';

class CommsService {

  constructor(axios) {
    this.axios = axios;
  }

  // Program Comms
  getProgramsComms(programId, search) {
    return this.axios.get(`/programs/${programId}/communications/topics`, {params: {search}});
  }
  postProgramsComms(programId, topicId, message) {
    return this.axios.post(`/programs/${programId}/communications/${topicId}/messages`, message);
  }
  updateProgramsComms(programId, topicId, data) {
    return this.axios.patch(`/programs/${programId}/communications/topics/${topicId}`, data);
  }
  postCreateTopic(programId, message) {
    return this.axios.post(`/programs/${programId}/communications/topics`, message);
  }
  getTopicMessageList(programId, topicId) {
    return this.axios.get(`/programs/${programId}/communications/topics/${topicId}/messages`);
  }
  createTopicMessage(programId, topicId, message) {
    return this.axios.post(`/programs/${programId}/communications/topics/${topicId}/messages`, message);
  }
  deleteProgramComm(programId, topicId) {
    return this.axios.delete(`/programs/${programId}/communications/topics/${topicId}`);
  }
  getCountUnread(programId, search) {
    return this.axios.get(`/programs/${programId}/communications/topics/unread-count`, {params: {search}});
  }
  getProgramUndeliverableMessages(programId, search) {
    return this.axios.get(`/programs/${programId}/communications/topics/undeliverable-messages`, {params: {search}});
  }
  postContactProgramManager(programId, message) {
    return this.axios.post(`/programs/${programId}/contact-program-manager`, message);
  }
  postTopicMessageMarkRead(programId, topics) {

    return this.axios.post(`/programs/${programId}/communications/topics/mark-read`, topics);
  }

  // Program Schedule
  getProgramsCommsScheduled(programId, search) {
    return this.axios.get(`/programs/${programId}/communications/topics/scheduled`, { params: { search } });
  }
  updateProgramCommsScheduled(programId, topicId, data) {
    return this.axios.patch(`/programs/${programId}/communications/topics/${topicId}`, data);
  }

  // Program Invite Template
  getProgramInviteTemplatesList(programId, search) {
    return this.axios.get(`/programs/${programId}/communications/message-templates`, { params: { search } });
  }
  createProgramInviteTemplate(programId, data) {
    return this.axios.post(`/programs/${programId}/communications/message-templates`, data);
  }
  getProgramInviteTemplate(programId, templateId) {
    return this.axios.get(`/programs/${programId}/communications/message-templates/${templateId}`);
  }
  updateProgramInviteTemplate(programId, templateId, data) {
    return this.axios.patch(`/programs/${programId}/communications/message-templates/${templateId}`, data);
  }
  deleteProgramInviteTemplate(programId, templateId) {
    return this.axios.delete(`/programs/${programId}/communications/message-templates/${templateId}`);
  }

  // Library Scheduled
  getCommsScheduled(search) {
    return this.axios.get(`/library/communications/topics/scheduled`, { params: { search } });
  }
  postCommsScheduled(data) {
    return this.axios.post(`/library/communications/topics/scheduled`, data);
  }
  postCommsTestSend(data) {
    return this.axios.post(`/library/communications/topics/test-send`, data);
  }
  postProgramCommsTestSend(programId, data) {
    return this.axios.post(`/programs/${programId}/communications/topics/test-send`, data);
  }
  deleteCommsScheduled(topicId) {
    return this.axios.delete(`/library/communications/topics/${topicId}`);
  }
  updateCommsScheduled(topicId, data) {
    return this.axios.patch(`/library/communications/topics/${topicId}`, data);
  }
  // GET users scheduled comms list
  getUserScheduledComms(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}/communications/topics/scheduled`);
  }
  getSentMessagesByUser(programId, userId, search) {
    return this.axios.get(`/programs/${programId}/users/${userId}/communications/topics/sent-messages`, { params: { search } });
  }
  getTopicSentMessageList(programId, topicId, search) {
    return this.axios.get(`/programs/${programId}/communications/topics/${topicId}/sent-messages`, { params: { search } });
  }
  runScheduledNow(programId, topicId) {
    return this.axios.post(`/commands/programs/${programId}/scheduled-messages/run-schedule/${topicId}`);
  }
}


const commsService = new CommsService(client);
export default commsService;
